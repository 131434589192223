import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import {
	Box,
	Flex,
	Image,
	Text,
	useTheme,
	useColorMode,
	AspectRatio,
	useClipboard,
	useToast,
} from '@chakra-ui/react';
import { IconShare } from '../../../assets/Icons';
import { showToast } from '../../../utils/Toasts';
import { PublicRoutes } from '../../../routes/Routes';

export const SliderPhotos = ({ projectImages, projectId }) => {
	const theme = useTheme();
	const toast = useToast();
	const { colorMode } = useColorMode();
	const [currentSlide, setCurrentSlide] = useState(1);

	const bulletColor =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	const bgBulletColor = theme.colors.transparencyWhite[800];

	const { onCopy } = useClipboard(
		`${window.location.origin}/${PublicRoutes.Project}/${projectId}`,
	);

	const getOnCopy = () => {
		onCopy();
		showToast(toast, 'Success', 'Link copied to clipboard', 1500, 'popup');
	};

	return (
		<Box w={'100vw'} mx={{ base: -5, md: '-30px' }} position='relative'>
			<Swiper
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				spaceBetween={0}
				slidesPerView={1}
				pagination={{ clickable: true }}
				onSlideChange={swiper =>
					setCurrentSlide(swiper.activeIndex + 1)
				}
			>
				{projectImages.map((img, idx) => (
					<SwiperSlide key={idx}>
						{img?.endsWith('.mp4') ? (
							<AspectRatio ratio={1} w='100%' h='375px'>
								<video
									title={`video-${idx}`}
									style={{
										margin: '0px',
										padding: '0px',
										border: 'none',
										objectFit: 'cover',
										borderRadius: '3px',
									}}
									src={img}
									autoPlay
									loop
									muted
								/>
							</AspectRatio>
						) : (
							<Image
								src={img}
								alt={`Slide ${idx}`}
								w='100%'
								h='375px'
								objectFit='cover'
							/>
						)}
					</SwiperSlide>
				))}

				<style>
					{`
						.swiper-pagination-bullet {
							width: 7px;
							height: 7px;
							background-color: ${bgBulletColor} ;
;
							opacity: 1 ;
							}
						.swiper-pagination-bullet-active {
							background-color: ${bulletColor} ;
						}
					`}
				</style>
			</Swiper>
			<Box
				position='absolute'
				bottom='20px'
				right='20px'
				bg='transparencyBlack.500'
				color='white'
				p='5px'
				borderRadius='3'
				zIndex={8}
			>
				<Text fontSize='10'>
					{currentSlide}/{projectImages.length}
				</Text>
			</Box>
			<Flex
				bg='transparencyWhite.800'
				w='30px'
				h='30px'
				borderRadius='50%'
				position='absolute'
				top='15px'
				right='30px'
				zIndex={9}
				align='center'
				justify='center'
				cursor={'pointer'}
				onClick={() => getOnCopy()}
				transition='0.3s'
				_hover={{ transform: 'scale(1.1)' }}
			>
				<IconShare color='transparencyBlack.800' boxSize='13px' />
			</Flex>
		</Box>
	);
};

export default SliderPhotos;
