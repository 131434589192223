import {
	Box,
	Step,
	StepDescription,
	StepIndicator,
	StepSeparator,
	StepStatus,
	StepTitle,
	Stepper,
	useColorModeValue,
	useTheme,
	useColorMode,
	Text,
} from '@chakra-ui/react';
import { IconCircleMiddlePoint } from '../../../assets/Icons';
import { formatDateLongMonth } from '../../../utils/utils';
import CardBoxComponent from '../../../components/CardBoxComponent';
import { useTranslation } from 'react-i18next';

const FundingTimeline = ({ projectInfo }) => {
	const { timelines } = projectInfo;
	timelines.sort((a, b) => new Date(a.start) - new Date(b.start));

	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const theme = useTheme();
	const dotColor = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);

	const themeColor = useColorModeValue(
		theme.colors.transparencyBlack[100],
		theme.colors.transparencyWhite[100],
	);

	const steps = timelines;

	let activeStep = steps?.findIndex(
		step => new Date(step.start) > new Date(),
	);

	if (activeStep === -1) {
		activeStep = steps?.length;
	}

	return (
		<CardBoxComponent title={t('projectDetails.fundingTimeline.title')}>
			{timelines.length ? (
				<Stepper
					index={activeStep}
					orientation='vertical'
					gap='0'
					colorScheme={
						colorMode === 'dark' ? 'transparencyWhite' : 'none'
					}
				>
					{steps?.map((step, index) => (
						<Step key={index}>
							<StepIndicator
								boxSize={6}
								border='none'
								bg={
									colorMode === 'dark'
										? 'transparencyWhite.100'
										: 'none'
								}
							>
								<StepStatus
									complete={
										<IconCircleMiddlePoint
											size={'30px'}
											color={dotColor}
										/>
									}
									incomplete={
										<Box
											as={IconCircleMiddlePoint}
											color={
												colorMode === 'dark'
													? 'transparencyWhite.950'
													: 'transparencyBlack.950'
											}
										/>
									}
									active={
										<Box
											as={IconCircleMiddlePoint}
											color={
												colorMode === 'dark'
													? 'transparencyWhite.950'
													: 'transparencyBlack.950'
											}
										/>
									}
								/>
							</StepIndicator>

							<Box mb='61px' _last={{ mb: 0 }}>
								<StepTitle
									fontSize='12'
									fontWeight='700'
									color={
										index <= activeStep
											? dotColor
											: 'inherit'
									}
								>
									{step.title}
								</StepTitle>
								<StepDescription
									fontSize='10'
									fontWeight='400'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.500'
											: 'transparencyBlack.500'
									}
									lineHeight='5'
								>
									{formatDateLongMonth(step?.start)}
									{step?.end &&
										` - ${formatDateLongMonth(step?.end)}`}
								</StepDescription>
								<StepDescription
									fontSize='10'
									fontWeight='400'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.900'
											: 'transparencyBlack.900'
									}
								>
									{step.description}
								</StepDescription>
							</Box>
							<StepSeparator
								ml='-5px'
								mt='-4px'
								borderColor={
									index < activeStep ? dotColor : themeColor
								}
								borderWidth='2px'
								rounded={5}
								borderStyle={
									index < activeStep ? 'dashed' : 'solid'
								}
							/>
						</Step>
					))}
				</Stepper>
			) : (
				<Text variant='gray' fontSize='12'>
					{t('projectDetails.fundingTimeline.noAddedTimeline')}
				</Text>
			)}
		</CardBoxComponent>
	);
};

export default FundingTimeline;
