/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Text, useColorMode, useToast } from '@chakra-ui/react';
import { showToast } from '../../../utils/Toasts';
import TooltipComponent from '../../../components/TooltipComponent';

/**
*
    @name TabComponent
    @description Contains the logic to disable each tab and to display the error toast.
    @returns {JS.Element} TabComponent.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TabComponent = ({
	index,
	title,
	icon,
	tabChangeDisabled,
	isActive,
	onSelectTab,
}) => {
	const toast = useToast();
	const { colorMode } = useColorMode();

	const handleToast = () =>
		showToast(
			toast,
			'Save changes',
			'Please complete your edits before switching tabs.',
		);

	return (
		<Box
			position='relative'
			onClick={tabChangeDisabled ? handleToast : onSelectTab}
			cursor={'pointer'}
		>
			<Flex
				direction='row'
				align='center'
				isDisabled={!isActive}
				maxH='28px'
				fontWeight={isActive ? '500' : '400'}
				borderRadius='5px'
				gap='5px'
				p='10px 7px'
				bg={isActive ? 'transparencyWhite.100' : undefined}
				opacity={tabChangeDisabled && 0.5}
			>
				{icon}
				<Text
					fontSize='12px'
					lineHeight='20px'
					color={colorMode === 'dark' ? 'white' : 'black'}
				>
					{title}
				</Text>
				{index === 3 && (
					<TooltipComponent label='This parameter outlines the timeline of the project, detailing each stage from start to finish. It provides a structured schedule of activities and milestones, such as financing, acquisition, setup, rental operations, or eventual sale. The timeline varies depending on the type of project, adapting to its specific processes and objectives. This helps ensure clarity and alignment for all stakeholders.' />
				)}
			</Flex>
		</Box>
	);
};

export default TabComponent;
