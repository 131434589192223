import {
	Menu,
	MenuButton,
	MenuDivider,
	MenuList,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';

import SubmenuComponent from './SubmenuComponent';
import { useNavigate } from 'react-router-dom';
import { resetUser } from '../../redux/states/user';
import { useDispatch } from 'react-redux';
import { PublicRoutes, PrivateRoutes, subMenuLinks } from '../../routes/Routes';
import useMagic from '../../hooks/useMagic';
import {
	IconExit,
	IconHelp,
	IconInvest,
	IconStaking,
} from '../../assets/Icons';
import { logout } from '../../services/authService';
import { useTranslation } from 'react-i18next';

const MenuComponent = ({ button, variant = 'simple' }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');

	const dispatch = useDispatch();
	const { logoutWithMagicLink } = useMagic();

	const navigate = useNavigate();

	const handleNavigate = route => {
		navigate(route);
	};

	const handleLogOut = async () => {
		try {
			await logout();
			await logoutWithMagicLink();
			dispatch(resetUser());

			navigate(`${PublicRoutes.Home}`, { replace: true });
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Menu variant={variant}>
			<MenuButton p={0} my='-1px'>
				{button}
			</MenuButton>
			<MenuList zIndex={10} minW='145px' maxW='145px' border='none'>
				{isTablet && (
					<>
						<SubmenuComponent
							icon={IconStaking}
							label={t(`subMenu.admin`)}
							onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
						/>
						<MenuDivider
							color={
								colorMode === 'dark'
									? 'transparencyWhite.300'
									: 'transparencyBlack.300'
							}
							m={0}
						/>
					</>
				)}

				{subMenuLinks.map((link, idx) => (
					<SubmenuComponent
						icon={link.icon}
						label={t(`subMenu.${link.title.toLocaleLowerCase()}`)}
						key={idx}
						onClick={() => handleNavigate(link.to)}
					/>
				))}
				<MenuDivider
					color={
						colorMode === 'dark'
							? 'transparencyWhite.300'
							: 'transparencyBlack.300'
					}
					m={0}
				/>
				<SubmenuComponent
					icon={IconHelp}
					label={t(`subMenu.support`)}
					onClick={() =>
						window.open(
							`https://elevex.zendesk.com/hc/en-us`,
							'_blank',
						)
					}
				/>
				<SubmenuComponent
					icon={IconInvest}
					label={t(`subMenu.tutorial`)}
					onClick={() =>
						window.open(
							`https://elevex.zendesk.com/hc/en-us/articles/22802185413138-Elevex-Testnet-Full-Tutorial-Part-1`,
							'_blank',
						)
					}
				/>

				<SubmenuComponent
					icon={IconExit}
					label={t(`subMenu.logout`)}
					onClick={handleLogOut}
				/>
			</MenuList>
		</Menu>
	);
};

export default MenuComponent;
