import axios from 'axios';
import { projectStatus } from '../data/optionsData.js';
import { getUpdateProjectSignature } from '../services/contractsServices/messageContractService.js';
import { performContractFunction } from '../services/ethereumServices.js';
import {
	normalizeUserProjectData,
	updateProperty,
	updatePropertyAndNonce,
} from '../services/propertyService.js';
import useContracts from './useContracts.js';
import { decodeToken } from '../utils/TokenUtils.js';

const useProject = () => {
	const { getElevexTokenContract } = useContracts();
	/**
	 * @name existsProject
	 * @description Check if a project exists
	 * @param {number} projectId - The project ID
	 * @returns {Promise<boolean>} - True if the project exists, false otherwise
	 */
	const existsProject = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const exists = await elevexTokenContract.existsProject(projectId);
			return exists;
		} catch (error) {
			console.error(
				'Error checking if project exists (existsProject):',
				error,
			);
			return null;
		}
	};

	/**
	 * @name createProjectAndUpdateStatus
	 * @description Create a project and update its status
	 * @param {Object} projectData - The project data
	 * @param {number} projectIdInDb - The project ID in the database
	 * @returns {Promise<TransactionReceipt>} - The created project transaction receipt
	 * @throws {Error} - If an error occurs
	 */
	const createProjectAndUpdateStatus = async (projectData, projectIdInDb) => {
		try {
			const elevexTokenContract = await getElevexTokenContract();

			const {
				projectId,
				projectType,
				totalSupply,
				projectPrice,
				withdrawAddress,
				paymentMethods,
				fundingThreshold,
				legalContract,
				nonce,
				signature,
			} = projectData;

			const createdProject = await performContractFunction(
				elevexTokenContract,
				'createProject',
				projectId,
				projectType,
				totalSupply,
				projectPrice,
				withdrawAddress,
				paymentMethods,
				fundingThreshold || 100,
				legalContract,
				nonce,
				signature,
			);

			let newProject = {};

			if (createdProject.success) {
				newProject = await updatePropertyAndNonce(
					{
						status: projectStatus.Published,
						nonce: projectData.nonce,
					},
					projectIdInDb,
				);

				return {
					newProject: newProject.data,
					receipt: createdProject.receipt,
				};
			} else {
				await updateProperty({ status: 'Draft' }, projectIdInDb);
				return {
					error: true,
					// reason: error.reason || error.message,
				};
			}
		} catch (error) {
			await updateProperty({ status: 'Reload' }, projectIdInDb);
			return {
				error: true,
				reason: error.reason || error.message,
			};
		}
	};

	/**
	 * @name updateProject
	 * @description Update a project
	 * @param {Object} projectData - The project data
	 */
	const updateProject = async projectData => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const signature = await getUpdateProjectSignature(projectData);
			const existProject = await existsProject(projectData.projectId);
			if (!existProject) {
				console.error('Project does not exist.');
			}

			const { projectId, withdrawAddress, paymentMethods, nonce } =
				projectData;

			if (await isNonceUsed(projectId)) {
				console.error('Nonce already used');
			}

			const updatedProject = await performContractFunction(
				elevexTokenContract,
				'updateProject',
				projectId,
				withdrawAddress,
				paymentMethods,
				nonce,
				signature,
			);

			return updatedProject;
		} catch (error) {
			console.error('Error updating project (updateProject):', error);
			return {
				error: true,
				reason: error.reason || error.message,
			};
		}
	};

	const withdrawProjectFunds = async ({ metadataId }) => {
		const elevexTokenContract = await getElevexTokenContract();

		const withdrawReceipt = await performContractFunction(
			elevexTokenContract,
			'withdrawProjectFunds',
			metadataId,
		);
		return withdrawReceipt;
	};

	// --------------------------------------------------
	// GETTERS
	// --------------------------------------------------

	/**
	 *
	 * @param {string} walletAddress The wallet address of the user
	 * @param {number} projectId The project Id (metadata id)
	 * @returns The number of tokens the user has for the specific project
	 */

	const balanceOf = async (walletAddress, projectId) => {
		try {
			const elevexTokenContract = await getElevexTokenContract();
			const balance = await elevexTokenContract.balanceOf(
				walletAddress,
				projectId,
			);
			return Number(balance);
		} catch (error) {
			console.error('Error getting balance (balanceOf):', error);
		}
	};

	/**
	 * @name getFullProject
	 * @description Get the information of a project
	 * @param {number} projectId - The project ID
	 * @returns {Promise<Object>} - The project information
	 */
	const getFullProject = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const project = await elevexTokenContract.getFullProject(projectId);
			return project;
		} catch (error) {
			console.error(
				'Error getting full project (getFullProject):',
				error,
			);
			return null;
		}
	};

	/**
	 * @name getProjectType
	 * @description - Get the project type
	 * @param {number}	projectId - The project ID
	 * @returns {Promise<number>} - The project type
	 */
	const getProjectType = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const projectType =
				await elevexTokenContract.getProjectType(projectId);
			return projectType;
		} catch (error) {
			console.error(
				'Error getting the projectType (getProjectType):',
				error,
			);
		}
	};

	/**
	 * @name getProjectMintedSupply
	 * @description - Get the project minted supply
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The project minted supply
	 */
	const getProjectMintedSupply = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const mintedSupply =
				await elevexTokenContract.getProjectMintedSupply(projectId);
			return parseInt(mintedSupply);
		} catch (error) {
			console.error(
				'Error getting the project minted supply (getProjectMintedSupply):',
				error,
			);
		}
	};

	/**
	 * @name getProjectMaxSupply
	 * @description - Get the project max supply
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The project max supply
	 */
	const getProjectMaxSupply = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const maxSupply =
				await elevexTokenContract.getProjectMaxSupply(projectId);
			return maxSupply;
		} catch (error) {
			console.error(
				'Error getting the project max supply (getProjectMaxSupply):',
				error,
			);
		}
	};

	/**
	 * @name getUnmintedSupply
	 * @description - Get the project unminted supply
	 * @param {number} projectId - The project ID
	 * @returns {Promise<string>} - The project unminted supply
	 */

	const getUnmintedSupply = async projectId => {
		const [mintedSupply, maxSupply] = await Promise.all([
			getProjectMintedSupply(projectId),
			getProjectMaxSupply(projectId),
		]);
		return (maxSupply - mintedSupply).toString();
	};

	/**
	 * @name getWithdrawAddress
	 * @description - Get the project's withdraw address
	 * @param {number} projectId - The project ID
	 * @returns {Promise<string>} - The withdraw address
	 */

	const getWithdrawAddress = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const withdrawAddress =
				await elevexTokenContract.getWithdrawAddress(projectId);
			return withdrawAddress;
		} catch (error) {
			console.error(
				'Error getting the withdraw address (getWithdrawAddress):',
				error,
			);
		}
	};

	/**
	 * @name getProjectPrice
	 * @description - Get the project price per token
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The project price per token
	 */
	const getProjectPrice = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const price = await elevexTokenContract.getProjectPrice(projectId);
			return price;
		} catch (error) {
			console.error(
				'Error getting the project price (getProjectPrice):',
				error,
			);
		}
	};

	/**
	 * @name getProjectPricePerToken
	 * @description - Get the price per token for the project
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The price per token
	 */

	const getProjectPricePerToken = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const pricePerToken =
				await elevexTokenContract.getProjectPricePerToken(projectId);
			return pricePerToken;
		} catch (error) {
			console.error(
				'Error getting the project price per token (getProjectPricePerToken):',
				error,
			);
		}
	};

	/**
	 * @name getProjectCreator
	 * @description - Get the project creator's address
	 * @param {number} projectId - The project ID
	 * @returns {Promise<string>} - The project creator's address
	 */

	const getProjectCreator = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const creator =
				await elevexTokenContract.getProjectCreator(projectId);
			return creator;
		} catch (error) {
			console.error(
				'Error getting the project creator (getProjectCreator):',
				error,
			);
		}
	};

	/**
	 * @name getProjectFundingThreshold
	 * @description - Get the project's funding threshold
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The project's funding threshold
	 */

	const getProjectFundingThreshold = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const threshold =
				await elevexTokenContract.getProjectFundingThreshold(projectId);
			return threshold;
		} catch (error) {
			console.error(
				'Error getting the project funding threshold (getProjectFundingThreshold):',
				error,
			);
		}
	};

	/**
	 * @name getProjectRewardAddress
	 * @description - Get the project's reward contract address
	 * @param {number} projectId - The project ID
	 * @returns {Promise<string>} - The reward contract address
	 */

	const getProjectRewardAddress = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const rewardAddress =
				await elevexTokenContract.getProjectRewardAddress(projectId);
			return rewardAddress;
		} catch (error) {
			console.error(
				'Error getting the project reward address (getProjectRewardAddress):',
				error,
			);
		}
	};

	/**
	 * @name isRefundActive
	 * @description - Check if a project is refundable
	 * @param {number} projectId - The project ID
	 * @returns {Promise<boolean>} - Whether the project is refundable
	 */

	const isRefundActive = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const refundActive =
				await elevexTokenContract.isRefundActive(projectId);
			return refundActive;
		} catch (error) {
			console.error(
				'Error checking if refund is active (isRefundActive):',
				error,
			);
		}
	};

	/**
	 * @name getProjectTotalRaised
	 * @description - Get the total amount raised for the project
	 * @param {number} projectId - The project ID
	 * @returns {Promise<number>} - The total amount raised
	 */

	const getProjectTotalRaised = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const totalRaised =
				await elevexTokenContract.getProjectTotalRaised(projectId);
			return totalRaised;
		} catch (error) {
			console.error(
				'Error getting the project total raised (getProjectTotalRaised):',
				error,
			);
		}
	};

	const getUserProjects = async userAddress => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const projects =
				await elevexTokenContract.getUserProjects(userAddress);
			const normalizedProjectData = normalizeUserProjectData(projects);
			const projectsData = await axios.post(
				'/project/user-projects',
				normalizedProjectData,
			);

			const tokenList = decodeToken(projectsData.data.data);
			const filteredList = tokenList.filter(
				item => item.project.mintedSupply > 0,
			);

			return filteredList;
		} catch (error) {
			console.error(
				'Error getting the user projects (getUserProjects):',
				error,
			);
		}
	};

	const getOneProjectByMetadataId = async (metadataId, mintedSupply) => {
		const data = [{ projectId: metadataId, mintedSupply }];
		const response = await axios.post('/project/user-projects', data);
		const decodedData = decodeToken(response.data.data);

		return decodedData[0];
	};

	/**
	 * @name isPaymentEnabled
	 * @description - Check if a specific token payment is enabled for the project
	 * @param {number} projectId - The project ID
	 * @param {string} token - The token symbol (e.g., "USDC")
	 * @returns {Promise<boolean>} - Whether the token payment is enabled
	 */

	const isPaymentEnabled = async (projectId, token) => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const paymentEnabled = await elevexTokenContract.isPaymentEnabled(
				projectId,
				token,
			);
			return paymentEnabled;
		} catch (error) {
			console.error(
				'Error checking if payment is enabled (isPaymentEnabled):',
				error,
			);
		}
	};

	/**
	 * @name isProjectBurnActive
	 * @description - Check if burn is active for a project
	 * @param {number} projectId - The project ID
	 * @returns {Promise<boolean>} - Whether burn is active for the project
	 */

	const isProjectBurnActive = async projectId => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const burnActive =
				await elevexTokenContract.isProjectBurnActive(projectId);
			return burnActive;
		} catch (error) {
			console.error(
				'Error checking if burn is active (isProjectBurnActive):',
				error,
			);
		}
	};

	/**
	 * @name isNonceUsed
	 * @description Return if a nonce has been used
	 * @param {number} nonce - The nonce
	 * @returns {Promise<boolean>} - True if the nonce has been used, false otherwise
	 */

	const isNonceUsed = async nonce => {
		const elevexTokenContract = await getElevexTokenContract();
		try {
			const hasBeenUsed = await elevexTokenContract.usedNonces(nonce);
			return hasBeenUsed;
		} catch (error) {
			console.error('Error getting nonce (isNonceUsed):', error);
			return null;
		}
	};

	return {
		existsProject,
		createProjectAndUpdateStatus,
		updateProject,
		getFullProject,
		balanceOf,
		isNonceUsed,
		getProjectType,
		getProjectMintedSupply,
		getProjectMaxSupply,
		getUnmintedSupply,
		getWithdrawAddress,
		getProjectPrice,
		getProjectPricePerToken,
		getProjectCreator,
		getProjectFundingThreshold,
		getProjectRewardAddress,
		getUserProjects,
		getOneProjectByMetadataId,
		isRefundActive,
		getProjectTotalRaised,
		isPaymentEnabled,
		isProjectBurnActive,
		withdrawProjectFunds,
	};
};

export default useProject;
