import {
	Divider,
	Flex,
	GridItem,
	Image,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import TooltipComponent from '../../../../components/TooltipTableComponent';
import { currencyFormatter, getCountryCode } from '../../../../utils/utils';
import OptionsComponent from './OptionsComponent';

const ListTokenGalleryInfoComponent = ({
	last,
	project,
	isTablet,
	colorBorder,
	onOpenSendModal,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 450px)');

	return (
		<>
			<TooltipComponent
				label={project?.title}
				w={'100%'}
				ps={2}
				fontSize={isTablet ? '10' : '14px'}
				fontWeight={isTablet ? '600' : '500'}
				textAlign='start'
			/>
			{!isMobile && (
				<Flex
					justify='center'
					w={isMobile ? '40px' : isTablet ? '80px' : '100px'}
				>
					<Image
						src={`/assets/countries/${getCountryCode(project?.country)}.webp`}
						boxSize='12px'
						rounded={'50px'}
					/>
				</Flex>
			)}
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{project.mintedSupply}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(project.value)}
			</Text>
			<GridItem colSpan={'span 2'}>
				<OptionsComponent
					onOpenSendModal={onOpenSendModal}
					project={project}
				/>
			</GridItem>
			{!last && (
				<Divider
					gridColumn={isTablet ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default ListTokenGalleryInfoComponent;
