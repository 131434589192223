import { Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';

const TooltipTableComponent = ({ label, isDisabled = false, ...props }) => {
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const [isSmallMobile] = useMediaQuery('(max-width: 320px)');
	const [isOpen, setIsOpen] = useState(false);

	const onClickOpenTooltip = () => {
		if (isTruncated) setIsOpen(!isOpen);
	};

	const isTruncated = isSmallMobile
		? label?.length > 5
		: isMobile
			? label?.length > 5
			: isTablet
				? label?.length > 19
				: label?.length > 25;

	return (
		<Tooltip label={label} placement='auto' isOpen={isOpen}>
			<Text
				{...props}
				cursor={'default'}
				isTruncated
				onClick={onClickOpenTooltip}
			>
				{label}
			</Text>
		</Tooltip>
	);
};

export default TooltipTableComponent;
