/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	FormLabel,
	Grid,
	Stack,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';

import {
	distributionFrequencyOptions,
	KYCOptions,
} from '../../../../data/optionsData';
import FormControlCustomComponent from './FormControlCustomComponent';

import AddDocumentsComponent from './AddDocumentsComponent';
import InfoBoxesComponent from './InfoBoxesComponent/InfoBoxesComponent';
import { availablePaymentMethods } from '../../data';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

const FinancialDetailsComponent = ({
	initialValues,
	handleOnChange,
	incorrectData,
	deleteFromIconrrectData,
	currentValues,
	isPurchased,
	informationTabData,
	category,
	subcategory,
	status,
	setPropertyInfo,
}) => {
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const [isMobile] = useMediaQuery('(max-width: 750px)');
	const { t } = useTranslation();
	const getDefaultPaymentMethods = () => {
		const currentPaymentMethod =
			currentValues?.projectMethodPayments ??
			initialValues?.projectMethodPayments;
		return currentPaymentMethod
			? Object.keys(currentPaymentMethod).filter(
					key => currentPaymentMethod[key],
				)
			: [];
	};

	const onChangeSelector = selection => {
		const obj = { ...currentValues, ...selection };
		const name = Object.keys(selection)[0];
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onChangeTextInput = event => {
		const { name, value } = event.target;
		const obj = {
			...currentValues,
			[name]: value,
		};
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onChangeNumberInput = event => {
		const { name, value, type } = event.target;
		let num;

		if (type === 'text') {
			const regex = /^[0-9.,]*$/;

			// Validar que el último carácter sea un número, una coma o un punto
			if (!regex.test(value)) {
				event.target.value = value.slice(0, -1);
				return;
			}

			const [integerPart, decimalPart] = value.split('.');

			const numericIntegerPart = integerPart.replace(/,/g, '');
			const formattedIntegerPart = new Intl.NumberFormat('en-US').format(
				numericIntegerPart,
			);

			const formattedValue =
				decimalPart !== undefined
					? `${formattedIntegerPart}.${decimalPart}`
					: formattedIntegerPart;

			event.target.value = formattedValue;

			num = event.target.value;
		} else {
			num = parseInt(value);
		}

		const obj = {
			...currentValues,
			[name]: value.length ? num : '',
		};
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onSelectPayment = values => {
		const selectedMethods = {
			eth: values.includes('eth'),
			usdc: values.includes('usdc'),
			usdt: values.includes('usdt'),
		};
		const obj = {
			...currentValues,
			projectMethodPayments: selectedMethods,
		};
		deleteFromIconrrectData('projectMethodPayments');
		handleOnChange(obj);
	};

	const onAddDocument = documents => {
		const obj = {
			...currentValues,
			...documents,
		};
		handleOnChange(obj);
	};

	return (
		<Flex flexDirection={isTablet ? 'column' : 'row'} gap='20px'>
			<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Financial parameters
				</Text>
				<Box gridColumn={isMobile ? 'span 12' : 'span 6'}>
					<FormLabel variant='propertyLabel'>
						Payments methods
					</FormLabel>
					<CheckboxGroup
						onChange={onSelectPayment}
						defaultValue={getDefaultPaymentMethods}
					>
						<Stack
							direction='row'
							h='40px'
							bg='transparencyWhite.100'
							borderRadius='5px'
							justifyContent='space-evenly'
							gap='15px'
							fontSize='10px'
							outline={
								incorrectData?.includes('projectMethodPayments')
									? '1px solid red'
									: 'none'
							}
						>
							{availablePaymentMethods.map(
								(paymentMethod, index) => (
									<Checkbox
										value={paymentMethod.value}
										key={index}
									>
										<Text fontSize='13px' fontWeight='400'>
											{paymentMethod.label}
										</Text>
									</Checkbox>
								),
							)}
						</Stack>
					</CheckboxGroup>
				</Box>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 6'}
					formLabel='KYC'
					tooltipLabel={t('addProject.FinancialDetailsComponent.KYC')}
					options={KYCOptions}
					onChange={onChangeSelector}
					name='isKYCRequired'
					incorrectData={incorrectData}
					selectedOption={
						currentValues?.isKYCRequired ??
						initialValues.data.isKYCRequired
					}
					deleteFromIconrrectData={deleteFromIconrrectData}
				/>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel='Price per token'
					tooltipLabel='The price assigned to each individual token in the project. Users will purchase tokens at this price, representing their share in the project.'
					symbol='$'
					name='pricePerToken'
					placeholder='Price per token'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.pricePerToken ??
						initialValues.data.pricePerToken
					}
					type='number'
					isDisabled={
						initialValues?.data?.pricePerToken &&
						status === 'Published'
					}
				/>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel='Quantity of Tokens'
					tooltipLabel='This refers to the total number of tokens available for purchase in the project. Each token represents a portion of the investment and its associated returns.'
					name='quantityOfTokens'
					placeholder='Quantity of Tokens'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.quantityOfTokens ??
						initialValues.data.quantityOfTokens
					}
					type='number'
					isDisabled={
						initialValues.data.quantityOfTokens &&
						status === 'Published'
					}
				/>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel='Payback Period'
					tooltipLabel='It indicates the number of months users must wait to start receiving returns on their investment.'
					symbol='Months'
					name='paybackPeriod'
					placeholder='Payback Period'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.paybackPeriod ??
						initialValues.data.paybackPeriod
					}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn={
						!isPurchased && !isMobile
							? 'span 6'
							: isMobile
								? 'span 12'
								: 'span 4'
					}
					formLabel='Min Ticket Price'
					tooltipLabel='The minimum amount required to purchase a token in the project. It sets the entry point for users to invest in the project.'
					symbol='$'
					name='minTicketPrice'
					placeholder='Min Ticket Price'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.minTicketPrice ??
						initialValues.data.minTicketPrice
					}
					type='number'
				/>
				{isPurchased && (
					<FormControlCustomComponent
						gridColumn={isMobile ? 'span 12' : 'span 4'}
						formLabel='Investment Threshold'
						tooltipLabel='Percentage required for a project to become funded.'
						symbol='%'
						name='investmentThreshold'
						placeholder='Investment Threshold'
						onChange={onChangeNumberInput}
						incorrectData={incorrectData}
						value={
							currentValues?.investmentThreshold ??
							initialValues.data.investmentThreshold
						}
						type='number'
						isDisabled={
							initialValues.data.investmentThreshold &&
							status === 'Published'
						}
					/>
				)}
				<FormControlCustomComponent
					gridColumn={
						!isPurchased && !isMobile
							? 'span 6'
							: isMobile
								? 'span 12'
								: 'span 4'
					}
					formLabel='Distribution Frequency'
					tooltipLabel='This refers to how often returns or payments will be distributed to users. It outlines whether distributions will occur monthly, quarterly, annually, or at another specified interval. The frequency distribution sets the schedule for when users can expect to receive their returns.'
					options={distributionFrequencyOptions}
					onChange={onChangeSelector}
					name='distributionFrequency'
					incorrectData={incorrectData}
					selectedOption={
						currentValues?.distributionFrequency ??
						initialValues.data.distributionFrequency
					}
					deleteFromIconrrectData={deleteFromIconrrectData}
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Wallet
				</Text>
				<FormControlCustomComponent
					gridColumn='span 12'
					formLabel='Wallet address to withdraw funds'
					tooltipLabel='Por definir'
					name='withdrawWalletAddress'
					placeholder='Wallet address to withdraw funds'
					onChange={onChangeTextInput}
					incorrectData={incorrectData}
					value={
						currentValues?.withdrawWalletAddress ??
						initialValues.data.withdrawWalletAddress
					}
				/>
				<Text
					gridColumn='span 12'
					fontSize='12px'
					fontWeight='400'
					mt='-18px'
				>
					<Text as='span' variant='colored' fontSize='12px'>
						Disclaimer:
					</Text>{' '}
					Please ensure that the address below is correct. If you send
					to an incorrect address we will not be able to recover the
					project funds
				</Text>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Project Cost Summary
				</Text>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel={
						subcategory === 'Rent & Rent'
							? 'Anual Rental Cost'
							: 'Current property value'
					}
					tooltipLabel='It indicates the current market value of the property.'
					symbol='$'
					name='currentPropertyValue'
					placeholder={
						subcategory === 'Rent & Rent'
							? 'Anual Rental Cost'
							: 'Current property value'
					}
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.currentPropertyValue
							? currentValues?.currentPropertyValue
							: initialValues.data.currentPropertyValue
								? numeral(
										initialValues.data.currentPropertyValue,
									).format('0,0.00')
								: initialValues.data.currentPropertyValue
					}
				/>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel='Taxes and operating costs'
					tooltipLabel="This includes all registration fees, property taxes, maintenance costs, and any additional fees that the Project Creator wishes to add. It covers the ongoing expenses necessary for the property's operation and compliance."
					symbol='$'
					name='taxesAndOperatingCosts'
					placeholder='Taxes and operating costs'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.taxesAndOperatingCosts
							? currentValues?.taxesAndOperatingCosts
							: initialValues.data.taxesAndOperatingCosts
								? numeral(
										initialValues.data
											.taxesAndOperatingCosts,
									).format('0,0.00')
								: initialValues.data.taxesAndOperatingCosts
					}
				/>
				<FormControlCustomComponent
					gridColumn={isMobile ? 'span 12' : 'span 4'}
					formLabel='Renovation and fitouts costs'
					tooltipLabel="These are the estimated costs associated with any renovations, improvements, or fitouts required for the property. These expenses are crucial for enhancing the property's value and generating higher returns."
					symbol='$'
					name='renovationAndFitoutsCosts'
					placeholder='Renovation and fitouts costs'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.renovationAndFitoutsCosts
							? currentValues?.renovationAndFitoutsCosts
							: initialValues.data.renovationAndFitoutsCosts
								? numeral(
										initialValues.data
											.renovationAndFitoutsCosts,
									).format('0,0.00')
								: initialValues.data.renovationAndFitoutsCosts
					}
				/>
				<Divider gridColumn='span 12' border='1px' />
				{(category === 'Rental' ||
					(category === 'Build' &&
						subcategory?.toLowerCase().includes('rent'))) && (
					<>
						<Text
							variant='colored'
							gridColumn='span 12'
							fontSize='20px'
							fontWeight='700'
							letterSpacing={-0.02}
						>
							Rental Income Overview
						</Text>
						<FormControlCustomComponent
							gridColumn={isMobile ? 'span 12' : 'span 6'}
							formLabel='Estimated Annual Net Income from Rental'
							tooltipLabel='The projected net income from rental activities on an annual basis, after deducting expenses such as maintenance, property management, and taxes'
							symbol='$'
							name='estimatedAnnualIncomeFromRental'
							placeholder='Estimated Annual Net Income from Rental'
							onChange={onChangeNumberInput}
							incorrectData={incorrectData}
							value={
								currentValues?.estimatedAnnualIncomeFromRental ??
								(initialValues.data
									.estimatedAnnualIncomeFromRental
									? numeral(
											initialValues.data
												.estimatedAnnualIncomeFromRental,
										).format('0,0.00')
									: initialValues.data
											.estimatedAnnualIncomeFromRental)
							}
						/>
						<FormControlCustomComponent
							gridColumn={isMobile ? 'span 12' : 'span 6'}
							formLabel='Rental Timeline'
							tooltipLabel='The estimated duration over which the property or asset will be rented out. It includes the period from the start of rental activities to the anticipated end date.'
							symbol='Months'
							name='rentalTimeline'
							placeholder='Rental Timeline'
							onChange={onChangeNumberInput}
							incorrectData={incorrectData}
							value={
								currentValues?.rentalTimeline ??
								initialValues.data.rentalTimeline
							}
							type='number'
						/>
						<Divider gridColumn='span 12' border='1px' />
					</>
				)}
				{subcategory !== 'Rent & Rent' && (
					<>
						<Text
							variant='colored'
							gridColumn='span 12'
							fontSize='20px'
							fontWeight='700'
							letterSpacing={-0.02}
						>
							Capital Growth and Estimated Returns
						</Text>
						<FormControlCustomComponent
							gridColumn={isMobile ? 'span 12' : 'span 6'}
							formLabel='Net Resale Price (After Fees and Taxes)'
							tooltipLabel='This parameter represents the final amount obtained from the resale of the property after deducting all associated costs, such as agency commissions and taxes. It reflects the actual proceeds from the sale, providing a clear picture of the net return.'
							symbol='$'
							name='estimatedResalePrice'
							placeholder='Net Resale Price (After Fees and Taxes)'
							onChange={onChangeNumberInput}
							incorrectData={incorrectData}
							value={
								currentValues?.estimatedResalePrice
									? currentValues?.estimatedResalePrice
									: initialValues.data.estimatedResalePrice
										? numeral(
												initialValues.data
													.estimatedResalePrice,
											).format('0,0.00')
										: initialValues.data
												.estimatedResalePrice
							}
						/>
						<FormControlCustomComponent
							gridColumn={isMobile ? 'span 12' : 'span 6'}
							formLabel='Appreciation'
							tooltipLabel='The expected increase in the value of the property annually. Can be estimated by averaging the market growth over the past 3 years in the project location.'
							name='appreciation'
							placeholder='Appreciation'
							symbol='%'
							onChange={onChangeNumberInput}
							incorrectData={incorrectData}
							value={
								currentValues?.appreciation
									? currentValues?.appreciation
									: initialValues.data.appreciation
										? numeral(
												initialValues.data.appreciation,
											).format('0,0.00')
										: initialValues.data.appreciation
							}
						/>
						<Divider gridColumn='span 12' border='1px' />
					</>
				)}
				<Box gridColumn='span 12'>
					<AddDocumentsComponent
						handleAddDocuments={documents =>
							onAddDocument(documents)
						}
						initialValues={initialValues}
						currentValues={currentValues}
						setPropertyInfo={setPropertyInfo}
						incorrectData={incorrectData}
						deleteFromIconrrectData={deleteFromIconrrectData}
						status={initialValues?.data?.status}
					/>
				</Box>
				{isTablet && <Divider gridColumn='span 12' border='1px' />}
			</Grid>
			<InfoBoxesComponent
				propertyInfo={{
					...initialValues.data,
					...currentValues,
					...informationTabData,
				}}
			/>
		</Flex>
	);
};

export default FinancialDetailsComponent;
