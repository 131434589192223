import { useEffect, useState } from 'react';
import {
	Flex,
	Link,
	Skeleton,
	Stack,
	Text,
	useColorModeValue,
	useMediaQuery,
} from '@chakra-ui/react';
import ClaimExperience from './ClaimExperience';
import BoxComponent from '../../../components/BoxComponent';
import { useTheme } from '@emotion/react';
import { IconWallet } from '../../../assets/Icons';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import useClaim from '../../../hooks/useClaim';

/**
*
    @name BalanceComponent
    @description Component that shows the balance of the user and the ready to claim balance. It allows to claim the ready to claim amount.
	@returns {JSX.Element} BalanceComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const BalanceComponent = ({ user, isTablet }) => {
	const { t } = useTranslation();
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const theme = useTheme();
	const iconColor = useColorModeValue(
		theme.colors.light.primary,
		theme.colors.dark.primary,
	);
	const [isOpen, setIsOpen] = useState(false);
	const closeModal = () => setIsOpen(false);
	const [balances, setBalances] = useState(user?.balances);
	const navigate = useNavigate();
	const { getAllRemainingToBeClaimed } = useClaim();
	const [totalToClaim, setTotalToClaim] = useState();

	useEffect(() => {
		const getTotalToClaim = async () => {
			const _totalToClaim = await getAllRemainingToBeClaimed(
				user.walletAddress,
			);
			setTotalToClaim(_totalToClaim);
		};
		getTotalToClaim();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setBalances(user.balances);
	}, [user]);

	return (
		<>
			<BoxComponent
				w={isMobile ? '100%' : '380.51px'}
				alignItems={'space-between'}
				h={isTablet ? 'auto' : '75px'}
				p='0'
				inPadding='10px 20px'
			>
				<Flex
					flexDir={isTablet ? 'column' : 'row'}
					justify={'space-around'}
				>
					<Flex
						justify={'center'}
						w='100%'
						gap={isTablet ? '40px' : '40px'}
					>
						<Stack gap='8px'>
							<Text
								variant='gray'
								fontSize='12'
								letterSpacing={-0.02}
								lineHeight='100%'
							>
								{t('dashboard.balance.currentAccountBalance')}
							</Text>
							{balances ? (
								<Text
									fontSize='16'
									fontWeight='700'
									lineHeight='100%'
								>
									{currencyFormatter(balances.usdBalance)}
								</Text>
							) : (
								<Skeleton h='16px' w='50px' />
							)}
							<Flex gap='10px' alignItems='center'>
								<IconWallet color={iconColor} boxSize='14px' />
								<Link
									variant='isActive'
									fontSize='10'
									lineHeight='100%'
									onClick={() =>
										navigate(`/${PrivateRoutes.Wallet}`)
									}
								>
									{t('dashboard.balance.accessWallet')}
								</Link>
							</Flex>
						</Stack>
						<Stack gap='8px'>
							<Text
								variant='gray'
								fontSize='12'
								letterSpacing={-0.02}
								lineHeight='100%'
							>
								{t('dashboard.balance.incomeReadyToClaim')}
							</Text>
							{totalToClaim >= 0 ? (
								<Text
									fontSize='16'
									fontWeight='700'
									lineHeight='100%'
								>
									{currencyFormatter(totalToClaim)}
								</Text>
							) : (
								<Skeleton h='16px' w='40px' />
							)}
						</Stack>
					</Flex>
				</Flex>
			</BoxComponent>

			<ClaimExperience closeModal={closeModal} isOpen={isOpen} />
		</>
	);
};

export default BalanceComponent;
