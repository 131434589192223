import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';
import {
	getAnnualInvestmentReturn,
	getCapitalGainOnSale,
	getEstimatedTotalROI,
} from '../../../../../../utils/projectDataUtils';

const CapitalGrowthAndEstimatedReturns = ({ data }) => {
	const { t } = useTranslation();
	const { estimatedResalePrice, appreciation } = data;

	return (
		<FinancialSummaryComponent
			title={t(
				'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.title',
			)}
		>
			{data?.subcategory !== 'Rent & Rent' && (
				<>
					<InfoSummaryComponent
						header={t(
							'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.estimatedResalePrice',
						)}
						amount={estimatedResalePrice}
					/>
					<InfoSummaryComponent
						header={t(
							'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.appreciation',
						)}
						amount={appreciation}
						porcent
					/>
				</>
			)}
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.capitalGainOnSale',
				)}
				amount={getCapitalGainOnSale(data)}
				total
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.estimatedTotalROI',
				)}
				amount={parseFloat(getEstimatedTotalROI(data))}
				total
				porcent
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.capitalGrowthAndEstimatedReturns.annualInvestmentReturn',
				)}
				amount={parseFloat(getAnnualInvestmentReturn(data))}
				total
				porcent
			/>
		</FinancialSummaryComponent>
	);
};

export default CapitalGrowthAndEstimatedReturns;
