import {
	Divider,
	Flex,
	GridItem,
	Text,
	Tooltip,
	useMediaQuery,
} from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';

const ProjectUpdatesTableInfo = ({
	update,
	updateIndex,
	last,
	isTablet,
	colorBorder,
	deleteUpdate,
	editUpdate,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 480px)');

	return (
		<>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '50px' : isTablet ? '100px' : '350px'}
			>
				<Tooltip label={update.title} placement={'top'}>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight={isTablet ? '600' : '500'}
						cursor={'default'}
						isTruncated
					>
						{update.title}
					</Text>
				</Tooltip>
			</GridItem>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '50px' : isTablet ? '100px' : '400px'}
			>
				<Tooltip label={update.description} placement={'top'}>
					<Text
						px='5px'
						variant='gray'
						fontSize={isTablet ? '10px' : '12px'}
						fontWeight='400'
						cursor={'default'}
						isTruncated
					>
						{update.description}
					</Text>
				</Tooltip>
			</GridItem>

			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isTablet ? '10px' : '25px'}
			>
				<IconEdit
					boxSize='14px'
					cursor='pointer'
					onClick={() => editUpdate(updateIndex)}
				/>
				<IconTrash
					boxSize='14px'
					cursor='pointer'
					onClick={() => deleteUpdate(updateIndex)}
				/>
			</Flex>
			{!last && <Divider gridColumn='span 4' borderColor={colorBorder} />}
		</>
	);
};

export default ProjectUpdatesTableInfo;
