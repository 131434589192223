import {
	Divider,
	Flex,
	Stack,
	Text,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IconView, IconTrade, IconTransfer } from '../../assets/Icons';
import { PublicRoutes } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';

const PropertyCardHover = ({ projectId, onSend }) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const linkColor =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	return (
		<Stack position='absolute' w='156px'>
			<Flex
				gap='10px'
				align='center'
				pl='40px'
				color='white'
				cursor='pointer'
				onClick={() =>
					navigate(`/${PublicRoutes.Project}/${projectId}`)
				}
				_hover={{ color: linkColor }}
			>
				<IconView boxSize='14px' />
				<Text fontWeight='400' fontSize='12px' lineHeight='150%'>
					{t('projectCard.projectCardHover.details')}
				</Text>
			</Flex>
			<Divider bg='transparencyWhite.200' />
			<Flex
				gap='10px'
				align='center'
				pl='40px'
				color='white'
				cursor='pointer'
				onClick={onSend}
				_hover={{ color: linkColor }}
			>
				<IconTransfer boxSize='14px' />
				<Text fontWeight='400' fontSize='12px' lineHeight='150%'>
					{t('projectCard.projectCardHover.send')}
				</Text>
			</Flex>
			<Divider bg='transparencyWhite.200' />
			<Flex
				gap='10px'
				align='center'
				pl='40px'
				color='white'
				cursor='pointer'
				onClick={() =>
					navigate(`/${PublicRoutes.Project}/${projectId}`)
				}
				_hover={{ color: linkColor }}
			>
				<IconTrade boxSize='14px' />
				<Text fontWeight='400' fontSize='12px' lineHeight='150%'>
					{t('projectCard.projectCardHover.buy')}
				</Text>
			</Flex>
			<Divider bg='transparencyWhite.200' />
		</Stack>
	);
};

export default PropertyCardHover;
