import { BrowserProvider, Contract, WebSocketProvider } from 'ethers';
import { magic } from '../lib/magic';
import { usdcABI } from '../interfaces/usdcABI';
import { usdtABI } from '../interfaces/usdtABI';
import { sellerAbi } from '../interfaces/seller.abi';
import { treasurySellerAbi } from '../interfaces/treasurySeller.abi';
import { elevexTokenAbi } from '../interfaces/elevexToken.abi';
import { claimAbi } from '../interfaces/claim.abi';
import { oracleABI } from '../interfaces/oracle.abi';

const useContracts = () => {
	let signer;

	// Addressess for the contracts initialization
	const contractAddresses = {
		usdc: process.env.REACT_APP_CONTRACT_ADDRESS_USDC,
		usdt: process.env.REACT_APP_CONTRACT_ADDRESS_USDT,
		treasuryClaim: process.env.REACT_APP_CONTRACT_ADDRESS_TREASURY_CLAIM,
		treasurySeller: process.env.REACT_APP_CONTRACT_ADDRESS_TREASURY_SELLER,
		oracle: process.env.REACT_APP_CONTRACT_ADDRESS_ORACLE,
		seller: process.env.REACT_APP_CONTRACT_ADDRESS_SELLER,
		elevexToken: process.env.REACT_APP_CONTRACT_ADDRESS_TOKEN,
		claim: process.env.REACT_APP_CONTRACT_ADDRESS_CLAIM,
	};

	// ABIs for the contracts initialization
	const abis = {
		usdc: usdcABI,
		usdt: usdtABI,
		seller: sellerAbi,
		treasurySeller: treasurySellerAbi,
		elevexToken: elevexTokenAbi,
		claim: claimAbi,
		oracle: oracleABI,
	};

	const provider = new WebSocketProvider(
		process.env.REACT_APP_WEBSOCKET_URL,
		'arbitrum-sepolia',
	);
	// const provider = new JsonRpcProvider(process.env.REACT_APP_RPC);
	const magicProvider = new BrowserProvider(magic.rpcProvider);

	/**
	 * @name getSigner
	 * @description - Get the signer of the contracts if it not initialized
	 * @returns {Signer} - The signer of the contracts
	 */

	const getSigner = async () => {
		if (!signer) {
			signer = await magicProvider.getSigner();
		}
		return signer;
	};

	const getContractWithSigner = async (address, abi) => {
		const signer = await getSigner();
		return new Contract(address, abi, signer);
	};

	const usdcContract = new Contract(
		contractAddresses.usdc,
		abis.usdc,
		provider,
	);
	const usdtContract = new Contract(
		contractAddresses.usdt,
		abis.usdt,
		provider,
	);

	/**
	 * @name getUsdcContract
	 * @description - Get the USDC contract with signer
	 * @returns {Contract} - The USDC contract with signer
	 */

	const getUsdcContract = () =>
		getContractWithSigner(contractAddresses.usdc, abis.usdc);

	/**
	 * @name getUsdtContract
	 * @description - Get the USDT contract with signer
	 * @returns {Contract} - The USDT contract with signer
	 */

	const getUsdtContract = () =>
		getContractWithSigner(contractAddresses.usdt, abis.usdt);

	/**
	 * @name getSellerContract
	 * @description - Get the seller contract with signer
	 * @returns {Contract} - The seller contract with signer
	 */

	const getSellerContract = () =>
		getContractWithSigner(contractAddresses.seller, abis.seller);

	/**
	 * @name getTreasurySellerContract
	 * @description - Get the treasury seller contract with signer
	 * @returns {Contract} - The treasury seller contract with signer
	 */

	const getTreasurySellerContract = () =>
		getContractWithSigner(
			contractAddresses.treasurySeller,
			abis.treasurySeller,
		);

	/**
	 * @name getTreasuryClaimContract
	 * @description - Get the treasury claim contract with signer
	 * @returns {Contract} - The treasury claim contract with signer
	 */

	const getTreasuryClaimContract = () =>
		getContractWithSigner(
			contractAddresses.treasuryClaim,
			abis.treasurySeller,
		);

	/**
	 * @name getElevexTokenContract
	 * @description - Get the elevex token contract with signer
	 * @returns {Contract} - The elevex token contract with signer
	 */

	const getElevexTokenContract = () =>
		getContractWithSigner(contractAddresses.elevexToken, abis.elevexToken);

	/**
	 * @name getClaimContract
	 * @description - Get the claim contract with signer
	 * @returns {Contract} - The claim contract with signer
	 */

	const getClaimContract = () =>
		getContractWithSigner(contractAddresses.claim, abis.claim);

	/**
	 * @name getOracleContract
	 * @description - Get the oracle contract with signer
	 * @returns {Contract} - The oracle contract with signer
	 */

	const getOracleContract = () =>
		getContractWithSigner(contractAddresses.oracle, abis.oracle);

	return {
		usdcContract,
		usdtContract,
		getSigner,
		getSellerContract,
		getTreasurySellerContract,
		getElevexTokenContract,
		getClaimContract,
		provider,
		getUsdcContract,
		getUsdtContract,
		getTreasuryClaimContract,
		getOracleContract,
		magicProvider,
	};
};

export default useContracts;
