import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from '../../../services/userService';
import {
	Button,
	Flex,
	Text,
	useColorMode,
	useMediaQuery,
	useTheme,
} from '@chakra-ui/react';
import ModalComponent from '../../../components/ModalComponent';
import { IconTrash } from '../../../assets/Icons';
import useMagic from '../../../hooks/useMagic';
import { useDispatch } from 'react-redux';
import { resetUser } from '../../../redux/states/user';
import { logout } from '../../../services/authService';
import { PublicRoutes } from '../../../routes/Routes';

const DeleteAccountModal = ({ isOpen, onClose }) => {
	const [isMobile] = useMediaQuery('(max-width: 480px)');
	const { colorMode } = useColorMode();
	const theme = useTheme();
	const navigate = useNavigate();
	const [errorMsg, setErrorMsg] = useState();
	const { logoutWithMagicLink } = useMagic();
	const dispatch = useDispatch();

	const onSubmit = async () => {
		try {
			await Promise.all([logout(), deleteUser()]);
			await logoutWithMagicLink();
			onClose();
			dispatch(resetUser());

			navigate(`${PublicRoutes.Home}`, { replace: true });
		} catch (error) {
			setErrorMsg(error.message);
		}
	};

	return (
		<ModalComponent isOpen={isOpen} onClose={onClose} modalCross={false}>
			<Flex
				w={isMobile ? '100%' : '490px'}
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.900'
				}
				border={`1px solid ${theme.colors.negative}`}
				flexDirection='column'
				textAlign='center'
				borderRadius='12px'
				p='20px'
				gap='20px'
			>
				<Text
					color='negative'
					fontWeight='700'
					fontSize='24px'
					letterSpacing={-0.02}
				>
					Confirm account deletion
				</Text>
				<Text fontWeight='400'>
					If you confirm, the account will be desactivated for a
					pediod of 15 days. If you log in again during this period,
					the account will be automatically reactivated. After 15
					days, the account will be completely deleted and cannot be
					restored.
				</Text>
				<Text fontWeight='600'>
					Do you confirm that you want to delete the account?
				</Text>
				<Flex w='100%' justify='center' gap='40px'>
					<Button variant='negative' gap='5px' onClick={onSubmit}>
						<IconTrash boxSize='14px' />
						<Text fontSize='13px'>Delete</Text>
					</Button>
					<Button
						variant='secondary'
						fontSize='13px'
						border={
							colorMode === 'dark'
								? `1px solid ${theme.colors.brand[200]}`
								: `1px solid ${theme.colors.brand[500]}`
						}
						onClick={onClose}
					>
						Cancel
					</Button>
				</Flex>
				{errorMsg && (
					<Text
						color='negative'
						fontSize='14px'
						fontWeight='700'
						lineHeight='5px'
					>
						{errorMsg}
					</Text>
				)}
			</Flex>
		</ModalComponent>
	);
};

export default DeleteAccountModal;
