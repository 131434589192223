import { HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import SmallBoxBigNumberComponent from './SmallBoxBigNumberComponent';
import { useTranslation } from 'react-i18next';

const MarketplaceTransactionsComponent = ({ isTablet, data }) => {
	const { t } = useTranslation();
	return (
		<Stack w='100%' h='100%' minH='150px' p='15px 25px' gap='10px'>
			<HStack justifyContent='space-between'>
				<Text
					fontSize='16px'
					fontWeight='700'
					letterSpacing={0.02}
					lineHeight='12px'
				>
					{t('adminPage.marketTransactions.title')}
				</Text>
				<Text
					fontSize='16px'
					fontWeight='700'
					letterSpacing={0.02}
					lineHeight='12px'
				>
					{data?.total}
				</Text>
			</HStack>
			<SimpleGrid columns={3} spacing='20px' w='100%' h='100%'>
				<SmallBoxBigNumberComponent
					bigText={data?.Rental || 0}
					smallText={t('adminPage.marketTransactions.rental')}
					isTablet={isTablet}
					blur={!data}
				/>
				<SmallBoxBigNumberComponent
					bigText={data?.Flip || 0}
					smallText={t('adminPage.marketTransactions.flip')}
					isTablet={isTablet}
					blur={!data}
				/>
				<SmallBoxBigNumberComponent
					bigText={data?.Build || 0}
					smallText={t('adminPage.marketTransactions.build')}
					isTablet={isTablet}
					blur={!data}
				/>
			</SimpleGrid>
		</Stack>
	);
};
export default MarketplaceTransactionsComponent;
