import { formatEther, BrowserProvider } from 'ethers';
import { formatBalanceWallet } from './walletService';
import { getNumberWithDecimals } from '../utils/utils';
import { magic } from '../lib/magic';

// const rpc = process.env.REACT_APP_RPC;
// export const provider = new JsonRpcProvider(rpc);
const magicRPC = magic.rpcProvider;
const magicProvider = new BrowserProvider(magicRPC);

/**
 * @name getEthereumBalance
 * @description Get the Ethereum balance
 * @param {string} walletAddress
 * @returns {Promise}
 */
export const getEthereumBalance = async (walletAddress, provider) => {
	try {
		// If not logged in and walletAddress does not exist return
		if (!walletAddress) return 0;

		const balance = await magicProvider.getBalance(walletAddress);
		return Math.round(formatEther(balance) * 100000) / 100000;
	} catch (error) {
		console.error(
			'Error getting the Ethereum balance (getEthereumBalance):',
			error,
		);
	}
};

/**
 * @name convertEthereuminUSD
 * @description Convert the Ethereum in USD
 * @param {number} ethAmount
 * @returns {Promise}
 */
export const convertEthereuminUSD = async (ethAmount, priceToken) => {
	try {
		const ethAmountNumber = parseFloat(ethAmount);
		return getNumberWithDecimals(
			ethAmountNumber * parseFloat(priceToken),
			5,
		);
	} catch (error) {
		console.error(
			'Error converting eth to usd (convertEthereuminUSD):',
			error,
		);
	}
};

/**
 * @name formatEthBalance
 * @description Format the Ethereum balance
 * @param {number} ethAmount
 * @returns {Promise}
 */
export const formatEthBalance = async ethAmount => {
	try {
		const formattedEthBalance = ethAmount.toFixed(3).toString();
		return formatBalanceWallet(formattedEthBalance);
	} catch (error) {
		console.error('Error formatting balance (formatEthBalance):', error);
	}
};

/**
 * @name performContractFunction
 * @description Perform a contract function
 * @param {Contract} contract
 * @param {string} functionName
 * @param {any} params
 * @returns {Promise}
 */

// parche para el error de error nonce has been used
let transactionRunning = false;

export const performContractFunction = async (
	contract,
	functionName,
	...params
) => {
	try {
		if (transactionRunning) return false;
		transactionRunning = true;
		await contract[functionName].staticCall(...params);

		const tx = await contract[functionName](...params);
		const receipt = await tx.wait();

		return { success: true, receipt, errorCode: null };
	} catch (error) {
		console.error(
			'staticCallTx Error performing contract function (performContractFunction):',
			error,
		);
		return {
			success: false,
			receipt: null,
			errorCode: error.code,
			errorMessage: error.reason,
		};
	} finally {
		transactionRunning = false;
	}
};
