import useContracts from './useContracts';
import { performContractFunction } from '../services/ethereumServices';
import { formatUnits, parseUnits } from 'ethers';
import { updateProperty } from '../services/propertyService';

const useClaim = () => {
	const {
		getClaimContract,
		getUsdcContract,
		getTreasuryClaimContract,
		getSigner,
		getElevexTokenContract,
		getOracleContract,
		getTreasurySellerContract,
	} = useContracts();

	/**
	 * @name transferClaimed
	 * @description - Transfer claimed tokens between users
	 * @param {string} from - The address that make the transaction
	 * @param {string} to - The address that receive the transaction
	 * @param {number} projectId - The project ID
	 * @param {number} tokenAmount - The amount of tokens transfered
	 * @returns
	 */

	const transferClaimed = async (from, to, projectId, tokenAmount) => {
		const claimContract = getClaimContract();
		try {
			const transferClaimed = await claimContract.transferClaimed(
				from,
				to,
				projectId,
				tokenAmount,
			);
			return transferClaimed;
		} catch (error) {
			console.error('Error transfering claimed:', error);
			return null;
		}
	};

	/**
	 * @name getRemainingToBeClaimed
	 * @description - Get and returns the remaning to be claimed from an user in a project
	 * @param {number} projectId - The project ID
	 * @param {string} walletAddress - The wallet addres where we want to check the remaning
	 * @returns {Promise<number>} - The remaining to be claimed
	 */

	const getRemainingToBeClaimed = async (projectId, walletAddress) => {
		const claimContract = await getClaimContract();

		try {
			const remainingToBeClaimed =
				await claimContract.getRemainingToBeClaimed(
					projectId,
					walletAddress,
				);

			return Number(remainingToBeClaimed);
		} catch (error) {
			console.error('Error getting remaining to be claimed:', error);
			return null;
		}
	};

	const getAllRemainingToBeClaimed = async walletAddress => {
		const claimContract = await getClaimContract();

		try {
			const remainingToBeClaimed =
				await claimContract.getAllRemainingToBeClaimed(walletAddress);

			return formatUnits(remainingToBeClaimed.toString(), 6);
		} catch (error) {
			console.error('Error getting all remaining to be claimed:', error);
			return null;
		}
	};

	/**
	 * @name getClaimedAmount
	 * @description - Get and returns the claimed amount an user has done in a project
	 * @param {number} projectId - The project ID
	 * @param {string} walletAddress - The wallet addres where we want to check the claimed amount
	 * @returns {Promise<number>} - The claimed amount of the user
	 */

	const getClaimedAmount = async (projectId, walletAddress) => {
		const claimContract = getClaimContract();

		try {
			const claimedAmount = await claimContract.getClaimedAmount(
				projectId,
				walletAddress,
			);
			return claimedAmount;
		} catch (error) {
			console.error('Error getting claimed amount:', error);
			return null;
		}
	};

	/**
	 * @name getUnclaimedRewards
	 * @description - Get and return the unclaimed rewards
	 * @param {number} projectId - The project ID
	 * @param {string} walletAddress - The wallet addres where we want to check the unclaimed rewards
	 * @returns {Promise<number>} - The unclaimed rewards
	 */

	const getUnclaimedRewards = async projectId => {
		const claimContract = getClaimContract();

		try {
			const unclaimedRewards =
				await claimContract.getUnclaimedRewards(projectId);
			return unclaimedRewards;
		} catch (error) {
			console.error('Error getting unclaimed rewards:', error);
			return null;
		}
	};

	const addFunds = async ({
		metadataId,
		projectId,
		tokenAmount,
		isFinalPayment = false,
	}) => {
		const [claimContract, treasurySellerContract] = await Promise.all([
			getClaimContract(),
			getTreasurySellerContract(),
		]);
		const tokenDecimals =
			await treasurySellerContract.getTokenDecimals('USDC');
		const tokenAmountInUnits = parseUnits(tokenAmount, tokenDecimals);
		const isAllowed = await checkAllowance({
			tokenAmount: tokenAmountInUnits,
		});
		if (!isAllowed) {
			return { success: false, message: 'Allowance not enough' };
		}

		if (isFinalPayment) updateProperty({ status: 'Ended' }, projectId);

		const addFundsTx = await performContractFunction(
			claimContract,
			'addFunds',
			metadataId,
			tokenAmountInUnits,
			isFinalPayment,
		);

		return addFundsTx;
	};

	const checkAllowance = async ({ tokenAmount }) => {
		const [signer, treasuryClaimContract, usdcContract] = await Promise.all(
			[getSigner(), getTreasuryClaimContract(), getUsdcContract()],
		);

		let allowance = await usdcContract.allowance(
			signer.address,
			treasuryClaimContract,
		);

		if (allowance !== tokenAmount) {
			const approvalTx = await usdcContract.approve(
				treasuryClaimContract,
				tokenAmount,
			);
			await approvalTx.wait(); // Espera a que la transacción de aprobación sea confirmada

			// Volver a obtener el nuevo allowance después de la aprobación
			allowance = await usdcContract.allowance(
				signer.address,
				treasuryClaimContract,
			);
		}
		return allowance === tokenAmount;
	};

	const getTotalTokenPrice = async (projectId, amount) => {
		const [elevexTokenContract, oracleContract, treasurySellerContract] =
			await Promise.all([
				getElevexTokenContract(),
				getOracleContract(),
				getTreasurySellerContract(),
			]);

		const price = await elevexTokenContract.getProjectPrice(projectId);
		const usdPrice = BigInt(amount) * price;

		const tokenDecimals =
			await treasurySellerContract.getTokenDecimals('USDC');

		const totalTokenPriceInUnits = await oracleContract.calculateUsdAmount(
			'USDC',
			tokenDecimals,
			usdPrice,
		);

		return totalTokenPriceInUnits;
	};

	const claim = async ({ metadataId }) => {
		const claimContract = await getClaimContract();
		return await performContractFunction(
			claimContract,
			'claim',
			metadataId,
		);
	};

	const checkUnclaimed = async (userProjects, walletAddress) => {
		if (userProjects?.length > 0) {
			const updatedProjects = await Promise.all(
				userProjects.map(async project => {
					if (
						project.project.status === 'Published' &&
						project.project.isWithdrawn
					) {
						const unclaimedRewards = await getRemainingToBeClaimed(
							project.project.metadataId,
							walletAddress,
						);
						if (unclaimedRewards > 0) {
							return {
								...project,
								project: {
									...project.project,
									claimed: false,
								},
							};
						} else {
							return {
								...project,
								project: {
									...project.project,
									claimed: true,
								},
							};
						}
					} else {
						return {
							...project,
							project: {
								...project.project,
								claimed: false,
							},
						};
					}
				}),
			);

			return updatedProjects;
		}
	};

	return {
		addFunds,
		claim,
		checkUnclaimed,
		getRemainingToBeClaimed,
		getAllRemainingToBeClaimed,
		getClaimedAmount,
		getUnclaimedRewards,
		transferClaimed,
	};
};

export default useClaim;
