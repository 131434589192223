import { Box, Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuComponent from './MenuComponent/MenuComponent';
import { headerLinks } from '../routes/Routes';
import { checkPath } from '../utils/utils';
import { IconDotsEmpty, IconEdit } from '../assets/Icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
*
    @name MenuComponentMobile
    @description Component for the mobile Navbar. It only include three links with their icons, and a menu link with different options
    @returns {JS.Element} MenuComponentMobile.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const MenuComponentMobile = () => {
	const userState = useSelector(store => store.user);
	const navigate = useNavigate();
	const location = useLocation();
	const { colorMode } = useColorMode();
	const { pathname } = location;
	const { t } = useTranslation();

	const iconsStyle = activePathnames => ({
		color: checkPath(pathname, activePathnames) ? 'brand.500' : 'black',
		_dark: {
			color: checkPath(pathname, activePathnames) ? 'brand.200' : 'white',
		},
	});

	return (
		<Box
			w='100%'
			py='10px'
			px='15px'
			position='fixed'
			bottom='0'
			bg={
				colorMode === 'dark'
					? 'transparencyBlack.300'
					: 'transparencyWhite.950'
			}
			sx={{
				backdropFilter: 'blur(10px)',
			}}
			zIndex={10}
		>
			<Flex h='45px' justifyContent='space-around'>
				{headerLinks.map((menu, idx) => {
					const MenuIcon = menu.icon;
					return (
						<Stack
							key={idx}
							alignItems='center'
							cursor='pointer'
							gap='4px'
							onClick={() => navigate(menu.to)}
							transition='color 0.2s'
							{...iconsStyle(menu.activePathnames)}
							fontFamily={'Space Grotesk'}
						>
							<MenuIcon boxSize='25px' />
							<Text fontSize='10' fontWeight='300'>
								{menu.title}
							</Text>
						</Stack>
					);
				})}
				{userState.username !== '' ? (
					<Stack
						alignItems='center'
						transition='color 0.2s'
						cursor='pointer'
						gap='4px'
						color={colorMode === 'dark' ? 'white' : 'black'}
						fontFamily={'Space Grotesk'}
					>
						<MenuComponent
							colorMode={colorMode}
							button={<IconDotsEmpty boxSize='25px' />}
							variant='mobileMenu'
						/>
						<Text fontSize='10' fontWeight='300'>
							Menu
						</Text>
					</Stack>
				) : (
					<Stack
						alignItems='center'
						cursor='pointer'
						gap='4px'
						onClick={() => navigate('/login')}
						transition='color 0.2s'
						fontFamily={'Space Grotesk'}
					>
						<IconEdit boxSize='25px' />
						<Text fontSize='10' fontWeight='300'>
							{t('subMenu.login')}
						</Text>
					</Stack>
				)}
			</Flex>
		</Box>
	);
};

export default MenuComponentMobile;
