import { Text, Box, useMediaQuery } from '@chakra-ui/react';
import DocumentList from './DocumentList';
import { useTranslation } from 'react-i18next';

/**
*
    @name DocumentsTab
    @description Component that displays a list of documents by language.
    @returns {JS.Element} React component that renders the documents.
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const DocumentsTab = ({ documents }) => {
	const { t } = useTranslation();
	const [isMobile] = useMediaQuery('(max-width: 450px)');

	const getFilteredDocuments = () => {
		const enDocuments = {
			documents: documents.filter(doc => doc.language === 'en'),
			language: 'en',
		};
		const esDocuments = {
			documents: documents.filter(doc => doc.language === 'es'),
			language: 'es',
		};

		return [enDocuments, esDocuments];
	};

	return (
		<>
			{documents.length ? (
				getFilteredDocuments().map((documentList, index) => {
					return (
						<>
							{documentList.documents.length ? (
								<Box key={index} pt={isMobile ? '0px' : '20px'}>
									<Text
										variant='colored'
										fontSize='20'
										fontWeight='bold'
										pb='12px'
									>
										{t(
											`projectDetails.projectMoreInfo.documentsTab.title.${documentList.language}`,
										)}
									</Text>
									<DocumentList
										documents={documentList.documents}
									/>
								</Box>
							) : null}
						</>
					);
				})
			) : (
				<Text>
					{t(
						'projectDetails.projectMoreInfo.detailsTab.noAddedDocuments',
					)}
				</Text>
			)}
		</>
	);
};

export default DocumentsTab;
