import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
	useMediaQuery,
} from '@chakra-ui/react';
import TimelineTable from './TimelineTable';
import DatePickerTimelineComponent from '../../../../components/DatePickerComponent/DatePickerTimelineComponent';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @name TimelineComponent
 * @description This component allows users to add, edit, and manage timeline steps for a given property. Each step contains a title, description, start date, and end date. Users can input new steps, edit existing ones, and view the timeline in a table format. The component manages state for creating and editing steps.
 * @param {Object} propertyInfo - The property information object, which includes the timeline data.
 * @param {Function} setPropertyInfo - A function to update the property information, including timeline steps.
 * @param {number | null} selectedStepIndex - The index of the step currently being edited, or null if adding a new step.
 * @param {Function} setSelectedStepIndex - A function to set the index of the step being edited.
 * @returns {JSX.Element} The TimelineComponent to handle the project's timeline steps.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

const newTimelineInitialValues = {
	title: '',
	description: '',
	startDate: null,
	endDate: null,
};

const TimelineComponent = ({
	initialValues,
	handleOnChange,
	currentValues,
	setTabChangeDisabled,
	setPropertyInfo,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 750px)');
	const [newTimeline, setNewTimeline] = useState();
	const newList = currentValues?.timeline ?? [];
	const updatedList = currentValues?.updatedTimeline ?? [];
	const allTimelines = initialValues.timeline
		.concat(updatedList)
		.concat(newList);
	const [editIndex, setEditIndex] = useState();
	const { t } = useTranslation();

	const disableAddButton =
		!newTimeline?.title?.length ||
		!newTimeline?.startDate ||
		!newTimeline?.endDate;

	const onChangeInput = event => {
		const { name, value } = event.target;
		setNewTimeline({ ...newTimeline, [name]: value });
	};

	const onChangeDate = event => {
		const { date, name } = event;

		setNewTimeline({
			...newTimeline,
			[name]: date,
		});
	};

	const onAddTimeline = () => {
		const newElement = {
			...newTimeline,
			id: Math.floor(Math.random() * 100),
		};
		const currentItems = newList.concat(newElement);
		setNewTimeline(newTimelineInitialValues);
		handleOnChange({
			...currentValues,
			timeline: currentItems,
		});
	};

	const onEditTimeline = selectedIndex => {
		setTabChangeDisabled(true);
		setEditIndex(selectedIndex);
		const selectedElement = allTimelines[selectedIndex];
		setNewTimeline(selectedElement);
	};

	const onCancelEdit = () => {
		setNewTimeline(newTimelineInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	const onSaveEditTimeline = () => {
		const element = newTimeline;
		let _newList = newList;
		let _updatedList = updatedList;
		if (element.timelineId) {
			const filteredList = updatedList.filter(
				item => item.timelineId !== newTimeline.timelineId,
			);
			const filteredInitialValues = initialValues?.timeline.filter(
				item => item.timelineId !== element.timelineId,
			);
			setPropertyInfo({
				...initialValues,
				timeline: filteredInitialValues,
			});
			_updatedList = filteredList.concat(element);
			handleOnChange({ ...currentValues, updatedTimeline: _updatedList });
		} else {
			const filteredList = newList.filter(
				item => item.id !== newTimeline.id,
			);
			element.title = newTimeline.title;
			element.description = newTimeline.description;
			element.startDate = newTimeline.startDate;
			element.endDate = newTimeline.endDate;
			_newList = filteredList.concat(element);
			handleOnChange({ ...currentValues, timeline: _newList });
		}
		setNewTimeline(newTimelineInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	const onDeleteTimeline = selectedIndex => {
		const element = allTimelines[selectedIndex];
		const deletedTimelines = currentValues?.deletedTimeline ?? [];

		if (element.timelineId) {
			const filteredInitialValues = initialValues?.timeline.filter(
				item => item.timelineId !== element.timelineId,
			);
			const filteredUpdatedList = updatedList.filter(
				item => item.timelineId !== element.timelineId,
			);
			setPropertyInfo({
				...initialValues,
				timeline: filteredInitialValues,
			});
			handleOnChange({
				...currentValues,
				deletedTimeline: deletedTimelines.concat(element),
				updatedTimeline:
					filteredUpdatedList.length > 0
						? filteredUpdatedList
						: undefined,
			});
		} else {
			const filteredList = newList.filter(item => item.id !== element.id);
			handleOnChange({
				...currentValues,
				timeline: filteredList,
			});
		}
	};

	// const addStep = () => {
	// 	if (
	// 		newStep.title &&
	// 		newStep.description &&
	// 		newStep.startDate &&
	// 		newStep.endDate
	// 	) {
	// 		setPropertyInfo(prevState => ({
	// 			...prevState,
	// 			timeline: [...prevState.timeline, newStep],
	// 		}));
	// 		setNewStep(newStepInitialValues);
	// 	}
	// };
	// const editStep = (step, stepIndex) => {
	// 	setSelectedStepIndex(stepIndex);
	// 	setEditingStep(step);
	// };

	// const cancelEdit = () => {
	// 	setEditingStep(newStepInitialValues);
	// 	setSelectedStepIndex(null);
	// };
	// const saveEditStep = () => {
	// 	if (selectedStepIndex !== null) {
	// 		setPropertyInfo(prevState => {
	// 			const updatedTimeline = [...prevState.timeline];
	// 			updatedTimeline[selectedStepIndex] = editingStep;
	// 			return {
	// 				...prevState,
	// 				timeline: updatedTimeline,
	// 			};
	// 		});

	// 		setEditingStep(newStepInitialValues);
	// 		setSelectedStepIndex(null);
	// 	}
	// };

	// const getFormattedDate = dateKey => {
	// 	const date =
	// 		selectedStepIndex !== null
	// 			? editingStep[dateKey]
	// 			: newStep[dateKey];
	// 	return date ? formatDateLongMonth(date) : '';
	// };

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn={isMobile ? 'span 12' : 'span 6'}>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.timeline.title')}
				</FormLabel>
				<Input
					placeholder='Add step title'
					name='title'
					value={newTimeline?.title}
					onChange={onChangeInput}
				/>
			</FormControl>

			<FormControl gridColumn={isMobile ? 'span 12' : 'span 3'}>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.timeline.start')}
				</FormLabel>

				<DatePickerTimelineComponent
					name='startDate'
					selected={newTimeline?.startDate}
					onChange={onChangeDate}
				/>
			</FormControl>

			<FormControl gridColumn={isMobile ? 'span 12' : 'span 3'}>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.timeline.end')}
				</FormLabel>

				<DatePickerTimelineComponent
					name='endDate'
					selected={newTimeline?.endDate}
					onChange={onChangeDate}
					disabled={!newTimeline?.startDate}
					minDate={newTimeline?.startDate}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.timeline.description')}
				</FormLabel>
				<Textarea
					rows={5}
					placeholder='Add step description'
					name='description'
					value={newTimeline?.description}
					onChange={onChangeInput}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{typeof editIndex === 'number' ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={onCancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button
							variant='main'
							gap='5px'
							onClick={onSaveEditTimeline}
						>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button
						variant='secondary'
						gap='5px'
						onClick={onAddTimeline}
						isDisabled={disableAddButton}
					>
						<IconPlus boxSize='13px' mt='-2px' />
						<Text fontSize='13px'>
							{t('addProperty.adminPanel.timeline.button')}
						</Text>
					</Button>
				)}
			</Flex>
			{allTimelines.length > 0 && (
				<Box gridColumn='span 12'>
					<TimelineTable
						currentTimelines={allTimelines}
						onEditTimeline={onEditTimeline}
						onDeleteTimeline={onDeleteTimeline}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default TimelineComponent;
