import { Grid, Stack, Text, useColorMode } from '@chakra-ui/react';
import InfoDashboardComponent from './InfoDashboardComponent';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getDashboardIncome } from '../../../services/userService';

/**
*
    @name IncomeComponent
    @description Component that allow the user to check the incomes he/she has, and how much money he/she received.
	@returns {JSX.Element} IncomeComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const IncomeComponent = () => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [incomesInfo, setIncomesInfo] = useState();
	const incomesData = incomesInfo?.data;

	useEffect(() => {
		const getIncomeInformation = async () => {
			const _overviewInfo = await getDashboardIncome();
			setIncomesInfo(_overviewInfo);
		};
		getIncomeInformation();
	}, []);

	return (
		<Stack
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.950'
			}
			h='100%'
			w='100%'
			border='0.5px solid '
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			p='15px 20px'
		>
			<Text fontWeight='700' fontSize='16' lineHeight='150%'>
				{t('dashboard.income.title')}
			</Text>
			<Grid
				templateColumns={'repeat(2, minmax(0, 1fr))'}
				h='100%'
				placeItems='center'
				gap='10px'
			>
				<InfoDashboardComponent
					header={t('dashboard.income.annualExpectedIncome')}
					info={currencyFormatter(incomesData?.annualExpectedIncome)}
					blur={incomesInfo?.success !== true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.lastMonth')}
					info={currencyFormatter(incomesData?.lastMonthIncome)}
					blur={incomesInfo?.success !== true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.nextPaymentIn')}
					info={incomesData?.nextPaymentDays + ' days'}
					blur={incomesInfo?.success !== true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.averageMonthlyIncome')}
					info={currencyFormatter(incomesData?.lastMonthIncome)}
					blur={incomesInfo?.success !== true}
				/>
			</Grid>
		</Stack>
	);
};

export default IncomeComponent;
