import { Contract, BrowserProvider } from 'ethers';
import { usdtABI } from '../interfaces/usdtABI';
import { formatBalanceWallet, oracle } from './walletService';
import { getNumberWithDecimals, getTokenAmount } from '../utils/utils';
import { magic } from '../lib/magic';

// const rpc = process.env.REACT_APP_RPC;
// export const provider = new JsonRpcProvider(rpc);
const magicRPC = magic.rpcProvider;
const magicProvider = new BrowserProvider(magicRPC);
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_USDT;
export const usdtContract = await new Contract(
	contractAddress,
	usdtABI,
	magicProvider,
);

// TODO: Hay que cambiar los decimales de 18 a 6
export const getUSDTBalance = async walletAddress => {
	try {
		const balance = await usdtContract.balanceOf(walletAddress);
		return getTokenAmount(balance, 18);
	} catch (error) {
		console.error(
			'Error getting the USDT balance (getUSDTBalance):',
			error,
		);
	}
};

export const convertUSDTinUSD = async (usdtAmount, priceToken) => {
	try {
		const usdtAmountNumber = parseFloat(usdtAmount);

		return getNumberWithDecimals(usdtAmountNumber * priceToken);
	} catch (error) {
		console.error(
			'Error converting usdt in usd (convertUSDTinUSD):',
			error,
		);
	}
};

export const formatBalanceUSDT = async usdtAmount => {
	try {
		const formattedUSDTBalance = usdtAmount.toFixed(3).toString();
		return formatBalanceWallet(formattedUSDTBalance);
	} catch (error) {
		console.error('Error formatting balance (formatBalanceUSDT):', error);
	}
};
