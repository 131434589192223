import {
	Text,
	Button,
	Stack,
	Flex,
	Icon,
	useColorMode,
	useClipboard,
	useToast,
	HStack,
} from '@chakra-ui/react';

import { IconCheck2, IconCopy } from '../assets/Icons';
import { useTranslation } from 'react-i18next';
import { normalizeTransactionHash } from '../utils/utils';
import BoxComponent from './BoxComponent';
import { showToast } from '../utils/Toasts';

/**
*
    @name SuccessfulBuyComponent
    @description Component to display a success message after a successful purchase or claim
    @returns {JS.Element} SuccessfulBuyComponent.js
	@author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SuccessfulComponent = ({
	p,
	w,
	h = 'fit-content',
	text,
	txHash,
	buttonTitle,
	modal,
	handleClick,
	handleClose,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const { onCopy } = useClipboard(txHash);
	const toast = useToast();

	const handleCopy = () => {
		onCopy();
		showToast(toast, 'Success', 'Transaction hash copied to clipboard');
	};

	const bgColorModal =
		colorMode === 'dark' ? 'transparencyWhite.100' : 'transparencyBlack.50';

	const bgColorGeneral =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.900';

	return (
		<Flex
			bg={!modal ? bgColorModal : bgColorGeneral}
			w={w}
			h={h}
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			borderRadius='12px'
			p={p}
			gap='10px'
		>
			<Text fontSize='20' fontWeight='800'>
				{t('projectDetails.offerDetails.succesful.title')}
			</Text>
			<Text
				fontSize='14'
				fontWeight='400'
				textAlign='center'
				flexWrap='wrap'
				letterSpacing={0.02}
				maxW={'100%'}
			>
				{text}
			</Text>
			{txHash && (
				<Stack w='100%'>
					<Stack
						bg={
							colorMode === 'dark'
								? 'transparencyBlack.400'
								: 'transparencyBlack.100'
						}
						borderRadius='100'
						p='20px'
						my='10px'
						mx='auto'
					>
						<Icon
							as={IconCheck2}
							color={
								colorMode === 'dark' ? 'brand.200' : 'brand.500'
							}
							boxSize='1.8em'
						/>
					</Stack>
					<Text
						fontSize='14'
						fontWeight='400'
						textAlign='center'
						flexWrap='wrap'
						letterSpacing={0.02}
						maxW={'100%'}
					>
						{t('projectDetails.offerDetails.succesful.txHash')}
					</Text>
					<BoxComponent>
						<Flex
							justifyContent='space-between'
							alignItems='center'
						>
							<Text
								fontSize='14'
								fontWeight='400'
								textAlign='center'
								letterSpacing={0.02}
								variant={'isActive'}
								href={`https://sepolia.arbiscan.io/tx/${txHash}`}
								isExternal
							>
								{normalizeTransactionHash(txHash, 20)}
							</Text>
							<IconCopy
								boxSize='16px'
								_hover={{
									transform: 'scale(1.1)',
								}}
								cursor='pointer'
								transition='transform 0.2s'
								onClick={handleCopy}
							/>
						</Flex>
					</BoxComponent>
				</Stack>
			)}

			<HStack>
				{handleClose ? (
					<Button onClick={handleClose}>
						{t('projectDetails.offerDetails.succesful.close')}
					</Button>
				) : null}
				<Button variant='main' onClick={handleClick}>
					{buttonTitle}
				</Button>
			</HStack>
		</Flex>
	);
};

export default SuccessfulComponent;
