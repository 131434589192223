import { Contract, JsonRpcProvider, WebSocketProvider } from 'ethers';
import { createContext, useEffect } from 'react';
import { usdcABI } from '../interfaces/usdcABI';
import { usdtABI } from '../interfaces/usdtABI';
import { useDispatch, useSelector } from 'react-redux';
import {
	convertTokenInUsd,
	getBalances,
	getUSDBalanceInWallet,
} from '../services/walletService';
import { updateUser } from '../redux/states/user';
import { getTokenAmount } from '../utils/utils';
import { getEthereumBalance } from '../services/ethereumServices';
import { cryptoCurrencies } from '../data/currencies';
import { sellerAbi } from '../interfaces/seller.abi';
import useProject from '../hooks/useProject';

const ContractContext = createContext();

export const ContractProvider = ({ children }) => {
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const { getUserProjects, getOneProjectByMetadataId } = useProject();

	const sellerContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_SELLER;
	const usdcContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_USDC;
	const usdtContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_USDT;

	const rpc = process.env.REACT_APP_RPC;
	const provider = new JsonRpcProvider(rpc);

	const websocketRpc = process.env.REACT_APP_WEBSOCKET_URL;
	const webSocketProvider = new WebSocketProvider(websocketRpc);

	const usdcContractWebsocket = new Contract(
		usdcContractAddress,
		usdcABI,
		webSocketProvider,
	);

	const usdtContractWebsocket = new Contract(
		usdtContractAddress,
		usdtABI,
		webSocketProvider,
	);

	const sellerContractWebsocket = new Contract(
		sellerContractAddress,
		sellerAbi,
		webSocketProvider,
	);

	const usdcContract = new Contract(usdcContractAddress, usdcABI, provider);
	const usdtContract = new Contract(usdtContractAddress, usdtABI, provider);

	useEffect(() => {
		if (!user?.walletAddress) return;

		const balancesget = async () => {
			const [balances, projects] = await Promise.all([
				getBalances(user?.walletAddress),
				getUserProjects(user?.walletAddress),
			]);

			dispatch(updateUser({ projects, balances }));
		};
		if (user.walletAddress.length > 0) balancesget();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.walletAddress]);

	useEffect(() => {
		if (!user?.walletAddress) return;
		if (
			user?.balances?.eth !== undefined &&
			user?.balances?.usdc !== undefined &&
			user?.balances?.usdt !== undefined
		) {
			usdcContractWebsocket.on('Transfer', (from, to, value, event) => {
				if (from === user.walletAddress || to === user.walletAddress) {
					updateTokenBalances({
						from,
						to,
						value,
						token: cryptoCurrencies.usdc.value,
						txHash: event.log.transactionHash,
					});
				}
			});

			usdtContractWebsocket.on('Transfer', (from, to, value, event) => {
				if (from === user.walletAddress || to === user.walletAddress) {
					updateTokenBalances({
						from,
						to,
						value,
						token: cryptoCurrencies.usdt.value,
						txHash: event.log.transactionHash,
					});
				}
			});

			sellerContractWebsocket.on(
				'MintProjectToken',
				async (buyer, projectId, amount) => {
					if (buyer === user.walletAddress) {
						const metadataId = Number(projectId);
						const mintedSupply = Number(amount);
						const exists = checkProjectExist(Number(projectId));
						if (!exists.success) {
							await handleMintedNewProject(
								metadataId,
								mintedSupply,
							);
						} else {
							handleChangeExistingProject(
								metadataId,
								mintedSupply,
								'mintedSupply',
							);
						}
					}
				},
			);

			const interval = setInterval(async () => {
				updateETHBalance();
			}, 20000);

			return () => {
				usdcContractWebsocket.removeAllListeners('Transfer');
				usdtContractWebsocket.removeAllListeners('Transfer');
				sellerContractWebsocket.removeAllListeners('MintProjectToken');
				clearInterval(interval);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const handleMintedNewProject = async (metadataId, mintedSupply) => {
		const project = await getOneProjectByMetadataId(
			metadataId,
			mintedSupply,
		);
		dispatch(
			updateUser({
				projects: [...user.projects, project],
			}),
		);
	};

	const handleChangeExistingProject = async (metadataId, amount, param) => {
		const index = findIndexOfProject(metadataId);
		if (index === -1) return;

		const updatedProjects = [...user.projects];
		const newMintedSupply = updatedProjects[index].project[param] + amount;
		const newValue =
			param === 'mintedSupply'
				? newMintedSupply * updatedProjects[index].project.pricePerToken
				: updatedProjects[index].project.value;

		updatedProjects[index] = {
			...updatedProjects[index],
			project: {
				...updatedProjects[index].project,
				mintedSupply: newMintedSupply,
				value: newValue,
			},
		};

		dispatch(
			updateUser({
				projects: updatedProjects,
			}),
		);
	};

	const checkProjectExist = metadataId => {
		const project = user?.projects?.find(project => {
			return project.project.metadataId === metadataId;
		});

		return { success: project !== undefined, project };
	};

	const findIndexOfProject = metadataId => {
		const index = user?.projects?.findIndex(project => {
			return project.project.metadataId === metadataId;
		});

		return index;
	};

	const updateTokenBalances = async ({ from, to, value, token, txHash }) => {
		if (!from || !to || !token) {
			console.error('Invalid transaction data:', { from, to, token });
			return;
		}

		if (from !== user.walletAddress && to !== user.walletAddress) {
			console.error('Transaction not related to the user:', { from, to });
			return;
		}

		if (from === to) {
			return;
		}

		let newTokenBalance = 0;
		let newUsdBalance = 0;

		const decimals = token === cryptoCurrencies.usdc.value ? 6 : 18;

		const tokenAmount = Number(getTokenAmount(value, decimals));

		const tokenBalanceInUsd = await convertTokenInUsd({
			token,
			amount: tokenAmount,
		});

		if (from === user.walletAddress) {
			newTokenBalance = user.balances[token] - tokenAmount;
			newUsdBalance = user.balances.usdBalance - tokenBalanceInUsd;
		} else {
			newTokenBalance = user.balances[token] + tokenAmount;
			newUsdBalance = user.balances.usdBalance + tokenBalanceInUsd;
		}

		dispatch(
			updateUser({
				balances: {
					...user.balances,
					[token]: Number(newTokenBalance.toFixed(3)),
					usdBalance: Number(newUsdBalance.toFixed(3)),
				},
			}),
		);
	};

	const updateETHBalance = async () => {
		const ethBalance = await getEthereumBalance(user.walletAddress);
		const newBalances = { ...user.balances, eth: ethBalance };

		if (ethBalance !== user.balances.eth) {
			const newUsdBalance = await getUSDBalanceInWallet(newBalances);
			// await checkBalanceChange();
			dispatch(
				updateUser({
					balances: {
						...user.balances,
						eth: ethBalance,
						usdBalance: newUsdBalance,
					},
				}),
			);
		}
	};

	return (
		<ContractContext.Provider value={{ usdcContract, usdtContract }}>
			{children}
		</ContractContext.Provider>
	);
};
