import { useEffect, useState } from 'react';
import useContracts from './useContracts';
import { BrowserProvider, formatEther, parseEther, parseUnits } from 'ethers';
import { magic } from '../lib/magic';
import { useSelector } from 'react-redux';
import { performContractFunction } from '../services/ethereumServices';
import { logActionOptions, logTypeOptions } from '../data/optionsData';
import { createLogHistory } from '../services/adminService';
import { cryptoCurrencies } from '../data/currencies';

const tokens = {
	usdc: 'USDC',
	usdt: 'USDT',
};

const useTokenTransfer = () => {
	const {
		getUsdcContract,
		getUsdtContract,
		getElevexTokenContract,
		getSigner,
	} = useContracts();
	const rpcProvider = magic.rpcProvider;
	const provider = new BrowserProvider(rpcProvider);
	const signerWallet = useSelector(state => state.user.walletAddress);

	/**
	 * @name sendTokens
	 * @description - Send tokens to a specific addresss
	 * @param {string} to The address to send the tokens to
	 * @param {string} token The token we want to send
	 * @param {number} amount The amount of tokens we want to send
	 * @returns true if the transaction was successful, false otherwise
	 */

	const sendTokens = async (to, token, amount) => {
		const signer = await getSigner();
		const contract =
			token === tokens.usdc
				? await getUsdcContract()
				: await getUsdtContract();
		if (contract) {
			const amountInWei =
				token === tokens.usdc
					? parseUnits(amount.toString(), 6)
					: parseUnits(amount.toString(), 18);

			const balance = await contract.balanceOf(signer.address);
			if (balance < amountInWei) {
				console.error('🚀 ~ sendTokens ~ Insufficient balance');
				return false;
			}

			const sendTokenTx = await performContractFunction(
				contract,
				'transfer',
				to,
				amountInWei,
			);
			return sendTokenTx;
		}
	};

	/**
	 * @name sendETH
	 * @description - Send ETH to a specific addresss
	 * @param {string} toAddress The address to send the ETH to
	 * @param {number} amount The amount of ETH we want to send
	 * @returns true if the transaction was successful, false otherwise
	 */

	const sendETH = async (toAddress, amount) => {
		const signer = await getSigner();
		if (signer) {
			try {
				const amountInWei = parseEther(amount.toString());

				const tx = {
					to: toAddress,
					value: amountInWei,
				};

				try {
					const gasEstimate = await signer.estimateGas(tx);
				} catch (gasError) {
					console.error(
						'🚫 La transacción probablemente fallará, error en la estimación de gas:',
						gasError,
					);
					return {
						success: false,
						error: 'Estimación de gas fallida, la transacción no se puede ejecutar',
					};
				}

				const transactionEth = await signer.sendTransaction(tx);
				const receipt = await transactionEth.wait();

				if (receipt) {
					await createLogHistory({
						action: logActionOptions.Send,
						type: logTypeOptions.Crypto,
						amount,
						currency: cryptoCurrencies.eth.label,
						from: receipt.from,
						to: receipt.to,
						tx: receipt.hash,
					});
				}
				return { success: true, receipt };
			} catch (error) {
				console.error(
					'🚀 ~ handleSendTokens ~ Error during transaction:',
					error,
				);
				return { success: false, error };
			}
		}
	};

	/**
	 * @name calculateMaxEthToSend
	 * @description - Calculate the maximum amount of ETH we can send
	 * @param {string} fromAddress The address we want to send the ETH from
	 * @param {string} toAddress The address we want to send the ETH to
	 * @returns The maximum amount of ETH we can send
	 */

	const calculateMaxEthToSend = async (fromAddress, toAddress) => {
		const signer = await getSigner();
		if (!signer) {
			return '';
		}
		const [feeData, balance, gasLimit] = await Promise.all([
			provider.getFeeData(),
			provider.getBalance(fromAddress),
			provider.estimateGas({
				to: toAddress,
				value: 0,
			}),
		]);
		const gasPrice = feeData.gasPrice;
		const gasCost = gasLimit * gasPrice;

		if (balance < gasCost) {
			console.error('No ETH enough to pay the gas cost');
			return;
		}

		const gasCostBuffer = gasCost + 10000000000000n;

		const maxSendableEth = balance - gasCostBuffer;

		return formatEther(maxSendableEth);
	};

	/**
	 * @name getEstimatedFee
	 * @description - Get the estimated fee for a transaction
	 * @returns The estimated fee for a transaction
	 * @returns {string} The estimated fee for a transaction
	 */

	const getEstimatedFee = async () => {
		const [feeData, gasLimit] = await Promise.all([
			provider.getFeeData(),
			provider.estimateGas({
				to: signerWallet,
				value: 0,
			}),
		]);

		const gasPrice = feeData.gasPrice;
		const gasCost = gasLimit * gasPrice;
		return formatEther(gasCost.toString());
	};

	const transferProjectTokens = async ({ from, to, metadataId, amount }) => {
		const tokenContract = await getElevexTokenContract();

		const userBalance = await tokenContract.balanceOf(from, metadataId);
		if (Number(userBalance) === 0) {
			return { success: false, error: 'No tokens to send' };
		}

		try {
			const tx = await tokenContract.safeTransferFrom(
				from,
				to,
				metadataId,
				amount,
				'0x',
			);
			const receipt = await tx.wait();

			return { success: true, receipt };
		} catch (error) {
			console.error('🚀 ~ transferProjectTokens ~ error:', error);
			return { success: false, error: error.reason };
		}
	};

	return {
		sendTokens,
		sendETH,
		calculateMaxEthToSend,
		getEstimatedFee,
		transferProjectTokens,
	};
};

export default useTokenTransfer;
