import { Flex, Radio, RadioGroup, Spacer, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IconTokenNFT, IconList } from '../../../../assets/Icons';

import CardTokenGallery from './CardTokenGallery';
import ListTokenGallery from './ListTokenGallery';
import { useTranslation } from 'react-i18next';
import { pageSizeCount } from '../../../../data/optionsData';
import SettingSelectorComponent from '../../../Settings/components/SettingSelectorComponent';
import PaginationComponent from '../../../../components/PaginationComponent';
import useProject from '../../../../hooks/useProject';
import { updateUser } from '../../../../redux/states/user';
import { useDispatch } from 'react-redux';

const ProjectTokensComponent = ({ isTablet, isMobile, user }) => {
	const [filter, setFilter] = useState('1');
	const [page, setPage] = useState(1);

	const { getUserProjects } = useProject();
	const dispatch = useDispatch();

	const handleChange = event => {
		setFilter(event.target.value);
	};

	const { t } = useTranslation();

	const [selectedPageSizeCount, setSelectedPageSizeCount] = useState('10');

	const selectedViewPageSize = pageSizeCount.find(
		obj => obj.label === selectedPageSizeCount,
	);

	const handleOnSelect = e => {
		const { value } = e.target;
		setSelectedPageSizeCount(value);
	};

	useEffect(() => {
		if (!user?.projects) {
			const getUserProjectsFromBlockchain = async () => {
				try {
					const projects = await getUserProjects(user.walletAddress);
					dispatch(updateUser({ projects }));
				} catch (error) {
					console.error(error);
				}
			};
			getUserProjectsFromBlockchain();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack
			gap='25px'
			gridColumn={{ lg: 'span 2' }}
			w={{ base: '100%', lg: '976px' }}
			justify='center'
			align='center'
			mb={isTablet ? '80px' : '0'}
		>
			<Text alignSelf='start' fontWeight='700' fontSize='32px'>
				{t('walletPage.projectTokens')}
			</Text>

			<Stack w={'100%'} flexDirection={'row'}>
				<RadioGroup alignSelf='start' value={filter}>
					<Radio
						value='1'
						variant='leftRounded'
						onChange={handleChange}
						p='10px'
					>
						<Flex gap='5px'>
							<IconTokenNFT boxSize='12px' />
							<Text
								fontSize='12px'
								fontWeight='400'
								lineHeight='100%'
							>
								Icon
							</Text>
						</Flex>
					</Radio>
					<Radio
						value='2'
						variant='rightRounded'
						onChange={handleChange}
						p='10px'
					>
						<Flex gap='5px'>
							<IconList boxSize='12px' />
							<Text
								fontSize='12px'
								fontWeight='400'
								lineHeight='100%'
							>
								List
							</Text>
						</Flex>
					</Radio>
				</RadioGroup>
				<Spacer />
				<Stack>
					<SettingSelectorComponent
						label={t('settings.appSettings.pageSizeCount')}
						options={pageSizeCount}
						onSelect={handleOnSelect}
						selectedOption={selectedViewPageSize}
						name='pageSizeCount'
						direction='row'
					/>
				</Stack>
			</Stack>

			{filter === '1' && (
				<CardTokenGallery
					isTablet={isTablet}
					isMobile={isMobile}
					projects={user.projects}
				/>
			)}
			{filter === '2' && (
				<ListTokenGallery
					projects={user.projects}
					isTablet={isTablet}
					isMobile={isMobile}
				/>
			)}
			{user?.projects &&
			user?.projects?.length > selectedPageSizeCount ? (
				<PaginationComponent
					totalProjects={user?.projects?.length}
					page={page}
					setPage={setPage}
					pageSize={6}
				/>
			) : null}
		</Stack>
	);
};

export default ProjectTokensComponent;
