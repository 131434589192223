export const currencies = [
	{ label: 'usd', value: 'usd' },
	{ label: 'eur', value: 'eur' },
];

export const cryptoCurrencies = {
	eth: { label: 'ETH', value: 'eth' },
	usdt: { label: 'USDT', value: 'usdt' },
	usdc: { label: 'USDC', value: 'usdc' },
};
