import { RadioGroup } from '@chakra-ui/react';

/**
*
    @name TagFilter
    @description Filter componenet that returns the properties with 3 conditions: All, purchased mode and pledge mode properties
    @returns {JS.Element} TagFilter.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TagFilter = ({ onChange }) => {
	return (
		<RadioGroup alignItems='center'>
			{/* <Radio
				value=''
				name='purchasedOrPledge'
				variant='leftRounded'
				onChange={onChange}
			>
				<Text fontSize='12px' fontWeight='light'>
					{t('tagFilter.all')}
				</Text>
			</Radio>
			<Radio
				value='Purchased'
				name='purchasedOrPledge'
				onChange={onChange}
			>
				<Text fontSize='12px' fontWeight='light'>
					{t('tagFilter.purchased')}
				</Text>
			</Radio>
			<Radio
				value='Pledge'
				name='purchasedOrPledge'
				variant='rightRounded'
				onChange={onChange}
			>
				<Text fontSize='12px' fontWeight='light'>
					{t('tagFilter.pledge')}
				</Text>
			</Radio> */}
		</RadioGroup>
	);
};

export default TagFilter;
