import AlertDialogComponent from './AlertDialogComponent';
import {
	Button,
	Flex,
	Icon,
	Link,
	Skeleton,
	Spinner,
	Stack,
	Text,
	useClipboard,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconCheck2, IconClose, IconCopy } from '../assets/Icons';
import BoxComponent from './BoxComponent';
import { showToast } from '../utils/Toasts';
import { normalizeTransactionHash } from '../utils/utils';

const AlertDialogSuccesfulTransaction = ({
	isOpen,
	txHash,
	onClose,
	text,
	errorMessage,
}) => {
	const { t } = useTranslation();
	const bgColor = useColorModeValue(
		'transparencyBlack.100',
		'transparencyBlack.400',
	);
	const iconColor = useColorModeValue('brand.500', 'brand.200');
	const iconErrorColor = useColorModeValue('red.500', 'red.500');
	const toast = useToast();

	const handleCopy = () => {
		onCopy();
		showToast(toast, 'Success', 'Transaction hash copied to clipboard');
	};

	const { onCopy } = useClipboard(txHash);
	return (
		<AlertDialogComponent isOpen={isOpen} noButtons={true} isCentered>
			<Stack spacing='5px' alignItems='center'>
				<Text fontSize='20' fontWeight='800'>
					{txHash
						? t(
								'projectDetails.offerDetails.succesful.transactionSuccess.title',
							)
						: errorMessage === '' || !errorMessage
							? 'Loading Transaction'
							: 'Transaction Failed'}
				</Text>
				<Text
					fontSize='14'
					fontWeight='400'
					letterSpacing={0.02}
					maxW={'100%'}
					textAlign='center'
				>
					{txHash
						? text
						: errorMessage === '' || !errorMessage
							? 'Please wait...'
							: 'Please try again in a few minutes'}
				</Text>
			</Stack>
			{!txHash && (errorMessage === '' || !errorMessage) ? (
				<Spinner w='48.8px' h='48.8px' my='30px' mx='auto' />
			) : (
				<Stack
					bg={bgColor}
					borderRadius='100'
					p='20px'
					my='10px'
					mx='auto'
				>
					<Icon
						as={
							errorMessage === '' || !errorMessage
								? IconCheck2
								: IconClose
						}
						color={
							errorMessage === '' || !errorMessage
								? iconColor
								: iconErrorColor
						}
						boxSize='1.8em'
					/>
				</Stack>
			)}
			{txHash && (
				<Text
					fontSize='14'
					fontWeight='400'
					textAlign='center'
					flexWrap='wrap'
					letterSpacing={0.02}
					maxW={'100%'}
				>
					{t('projectDetails.offerDetails.succesful.txHash')}
				</Text>
			)}
			{errorMessage && (
				<Text px='15px' variant='error' fontSize='12px'>
					{errorMessage}
				</Text>
			)}
			<BoxComponent>
				<Flex justifyContent='space-between' alignItems='center'>
					{txHash ? (
						<>
							<Text
								fontSize='14'
								fontWeight='400'
								textAlign='center'
								letterSpacing={0.02}
								variant={'isActive'}
								href={`https://sepolia.arbiscan.io/tx/${txHash}`}
								isExternal
							>
								{normalizeTransactionHash(txHash)}
							</Text>
							<IconCopy
								boxSize='16px'
								_hover={{
									transform: 'scale(1.1)',
								}}
								cursor='pointer'
								transition='transform 0.2s'
								onClick={handleCopy}
							/>
						</>
					) : errorMessage === '' || !errorMessage ? (
						<Skeleton h='21px' w='100%' />
					) : null}
				</Flex>
			</BoxComponent>
			<Flex px='10px' justifyContent='space-between'>
				<Button
					variant='secondary'
					onClick={onClose}
					isDisabled={!txHash && !errorMessage === ''}
				>
					Close
				</Button>
				<Button
					as={Link}
					variant='main'
					href={`https://sepolia.arbiscan.io/tx/${txHash}`}
					isExternal
					isDisabled={!txHash && !errorMessage !== ''}
				>
					Go to Arbiscan
				</Button>
			</Flex>
		</AlertDialogComponent>
	);
};

export default AlertDialogSuccesfulTransaction;
