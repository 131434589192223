import { IconWallet } from '../../../assets/Icons';
import {
	Box,
	Button,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
	useTheme,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';
import PropertyCardComponent from '../../../components/ProjectCardComponent/PropertyCardComponent';

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
@name MyPropertiesComponent
@description Components that list all the properties the user has.
@returns {JSX.Element} MyPropertiesComponent.js
@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
@Version 1.0.0
*/

const MyPropertiesComponent = ({ projects = [] }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const [isTablet] = useMediaQuery('(max-width: 768px)');
	const maxCards = isMobile ? 1 : isTablet ? 2 : 2;
	const theme = useTheme();

	const bulletColor =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	const bgBulletColor = theme.colors.transparencyWhite[800];

	return (
		<BoxComponent
			w='100%'
			h={
				isTablet
					? 'auto'
					: projects?.length <= 1
						? 'fit-content'
						: '100%'
			}
			overflowY={isTablet ? 'none' : 'scroll'}
			p='0'
			inPadding='15px 20px'
			gap='15px'
		>
			<Text fontWeight='bold'>{t('dashboard.myProperties.title')}</Text>

			<Stack
				w='100%'
				alignItems='center'
				gap='15px'
				align='center'
				justify='center'
			>
				{projects?.length > 0 ? (
					<>
						{maxCards === 2 ? (
							<Stack flexDirection={isTablet ? 'row' : 'column'}>
								{projects
									.slice(0, maxCards)
									.map(({ project, gallery }) => (
										<PropertyCardComponent
											key={project.id}
											h='340px'
											project={project}
											isMobile={isMobile}
											gallery={gallery}
										/>
									))}
							</Stack>
						) : (
							<Box maxW='312px' h='fit-content' mb='-5px'>
								<Swiper
									modules={[Pagination]}
									spaceBetween={20}
									slidesPerView={1}
									pagination={{
										clickable: true,
									}}
									style={{
										width: '100%',
										height: '370px',

										aspectRatio: '16 / 12',
									}}
								>
									{projects.map(({ gallery, project }) => (
										<SwiperSlide key={project.id}>
											<PropertyCardComponent
												project={project}
												isMobile={isMobile}
												gallery={gallery}
											/>
										</SwiperSlide>
									))}
									<style>
										{`
											.swiper-pagination-bullet {
												width: 7px;
												height: 7px;
												background-color: ${bgBulletColor};
												opacity: 1 ;
												}
											.swiper-pagination-bullet-active {
												background-color: ${bulletColor};
											}
										`}
									</style>
								</Swiper>
							</Box>
						)}
					</>
				) : (
					<Text>{t('dashboard.myProperties.noTokens')}</Text>
				)}

				<Button
					variant='main'
					fontWeight='400'
					leftIcon={
						<IconWallet
							boxSize='13px'
							color={
								colorMode === 'dark'
									? 'transparencyBlack.500'
									: 'transparencyWhite.900'
							}
						/>
					}
					p='10px 25px'
					alignSelf='center'
					onClick={() => navigate(`/${PrivateRoutes.Wallet}`)}
				>
					{t('dashboard.myProperties.button')}
				</Button>
			</Stack>
		</BoxComponent>
	);
};

export default MyPropertiesComponent;
