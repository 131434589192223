import BoxComponent from '../../../../components/BoxComponent';
import {
	Box,
	Divider,
	Grid,
	GridItem,
	Text,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SendProjectTokensComponent from '../../../../components/SendProjectTokensComponent';
import { useState } from 'react';
import TooltipComponent from '../../../../components/TooltipTableComponent';
import ListTokenGalleryInfoComponent from './ListTokenGalleryInfoComponent';

const ListTokenGallery = ({ projects, isTablet, isMobile }) => {
	const { colorMode } = useColorMode();
	const { t } = useTranslation();
	const [selectedProject, setSelectedProject] = useState();
	const {
		isOpen: isOpenSendModal,
		onOpen: onOpenSendModal,
		onClose: onCloseSendModal,
	} = useDisclosure();

	const handleSendModal = metadataId => {
		const _project = projects.find(
			project => project.project.metadataId === metadataId,
		);
		setSelectedProject(_project);
		onOpenSendModal();
	};

	const colorHeadTable = colorMode === 'dark' ? 'brand.200' : 'brand.500';
	const colorBorder =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyBlack.200';

	return (
		<BoxComponent w='100%' h='100%' p='0' inPadding='15px' gap='11px'>
			<Grid
				templateColumns={isMobile ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)'}
				placeItems='center'
				textAlign='center'
				fontSize={isTablet ? '12px' : '14px'}
				border={isTablet ? '2px solid' : 'none'}
				borderColor={colorBorder}
				borderRadius={isTablet ? '12px' : '0'}
				rowGap={'0'}
			>
				<GridItem
					borderRight={
						isTablet ? `2px solid ${colorHeadTable}` : 'none'
					}
					px='5px'
					py={isTablet ? '12px' : '5px'}
					w='100%'
					h='100%'
				>
					{isMobile ? (
						<TooltipComponent
							label={'Project name'}
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							textAlign='start'
							wordBreak='break-all'
						/>
					) : (
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							textAlign={'start'}
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
							cursor={'default'}
							isTruncated
						>
							{'Project name'}
						</Text>
					)}
				</GridItem>
				{!isMobile && (
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorHeadTable}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{'Country'}
						</Text>
					</GridItem>
				)}
				<GridItem
					borderRight={
						isTablet ? `2px solid ${colorHeadTable}` : 'none'
					}
					px='5px'
					py={isTablet ? '12px' : '5px'}
					w={isMobile ? '50px' : '100%'}
					h='100%'
				>
					{isMobile ? (
						<TooltipComponent
							label={t('dashboard.tokenHoldings.totalToken')}
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						/>
					) : (
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
							cursor={'default'}
							isTruncated
						>
							{t('dashboard.tokenHoldings.totalToken')}
						</Text>
					)}
				</GridItem>
				<GridItem
					borderRight={
						isTablet ? `2px solid ${colorHeadTable}` : 'none'
					}
					px='5px'
					py={isTablet ? '12px' : '5px'}
					w='100%'
					h='100%'
				>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight='700'
						lineHeight='100%'
						variant='colored'
						mb={isTablet ? '0px' : '6px'}
						wordBreak='break-all'
					>
						{'Fiat value'}
					</Text>
				</GridItem>
				<GridItem
					borderRight={
						isTablet ? `2px solid ${colorHeadTable}` : 'none'
					}
					px='5px'
					py={isTablet ? '12px' : '5px'}
					w='100%'
					h='100%'
				>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight='700'
						lineHeight='100%'
						variant='colored'
						mb={isTablet ? '0px' : '6px'}
						wordBreak='break-all'
					>
						Options
					</Text>
				</GridItem>
				<Divider
					gridColumn={isTablet ? 'span 5' : 'span 6'}
					borderWidth='1px'
					borderColor={colorBorder}
				/>

				{!projects || projects?.length === 0 ? (
					<Box w='100%' pt='15px'>
						<Text textAlign='center' w='100%'>
							{t('dashboard.myProperties.noTokens')}
						</Text>
					</Box>
				) : (
					projects.map((item, index) => {
						return (
							<ListTokenGalleryInfoComponent
								key={index}
								project={item.project}
								last={index === projects.length - 1}
								isTablet={isTablet}
								colorBorder={colorBorder}
								index={index}
								onOpenSendModal={handleSendModal}
							/>
						);
					})
				)}
			</Grid>
			{isOpenSendModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendModal}
					closeSendModal={onCloseSendModal}
					isMobile={isMobile}
					project={selectedProject.project}
				/>
			)}
		</BoxComponent>
	);
};

export default ListTokenGallery;
