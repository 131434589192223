import {
	Box,
	Flex,
	Spacer,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponentMobile from '../components/MenuComponentMobile';
import LogoComponent from '../components/LogoComponent';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { me } from '../services/authService';
import { resetUser, updateUser } from '../redux/states/user';
import { useTranslation } from 'react-i18next';
import useMagic from '../hooks/useMagic';
import DarkLightModeComponent from '../components/DarkLightModeComponent';
import SelectorLenguageComponent from '../components/SelectorLenguageComponent';

const AppLayout = ({ secondary, component }) => {
	const dispatch = useDispatch();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const { t } = useTranslation();
	const { logoutWithMagicLink } = useMagic();

	const { colorMode, toggleColorMode } = useColorMode();

	useEffect(() => {
		const getUser = async () => {
			const user = await me();
			if (user.success) {
				dispatch(updateUser(user.data));
			} else {
				dispatch(resetUser());
				logoutWithMagicLink();
			}
		};

		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex minH='100vh' flexDirection='column'>
			<Box bg='brand.200' w='100%'>
				<Text
					fontSize='sm'
					color={'black'}
					textAlign={'center'}
					py={0.5}
				>
					{t('warningTestnet')}
				</Text>
			</Box>

			{isTablet ? (
				<Flex py='15px' px={2} justifyContent='center' gap={5}>
					<Flex justifyContent={'center'} alignItems={'center'}>
						<DarkLightModeComponent
							colorMode={colorMode}
							toggleColorMode={toggleColorMode}
						/>
					</Flex>
					<Spacer />
					<LogoComponent onClick={toggleColorMode} />
					<Spacer />
					<SelectorLenguageComponent />
				</Flex>
			) : (
				<HeaderComponent px={{ base: 0, md: 10 }} />
			)}
			<Flex w='100%' flex='1' justify='center'>
				<Flex
					w='100%'
					maxW={secondary ? '1140px' : '1920px'}
					flexDirection='column'
					px={
						secondary
							? { base: '20px', md: '30px', '2xl': '0' }
							: { base: '20px', md: '30px' }
					}
				>
					<Box
						w='100%'
						flex='1'
						pb={isTablet ? '90px' : '30px'}
						pt={isTablet ? '0px' : '20px'}
					>
						{component}
					</Box>
				</Flex>
			</Flex>
			{isTablet && <MenuComponentMobile />}
		</Flex>
	);
};
export default AppLayout;
