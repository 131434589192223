import ModalComponent from '../../../components/ModalComponent';
import BoxComponent from '../../../components/BoxComponent';
import {
	Button,
	Checkbox,
	Divider,
	Flex,
	Image,
	Input,
	Stack,
	Text,
} from '@chakra-ui/react';
import { AWS_S3 } from '../../../constants/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ModalAddReward = ({
	isOpen,
	onAddReward,
	onClose,
	data,
	errorMessage,
	setErrorMessage,
}) => {
	const [tokenAmount, setTokenAmount] = useState(null);
	const [isFinalPayment, setIsFinalPayment] = useState(false);
	const [isAddingFunds, setIsAddingFunds] = useState(false);
	const { t } = useTranslation();

	const handleChangeValue = e => {
		if (errorMessage) setErrorMessage();
		const value = e.target.value;
		setTokenAmount(value);
	};

	const handleChangeIsFinalPayment = e => {
		setIsFinalPayment(e.target.checked);
	};

	const handleClick = async () => {
		setIsAddingFunds(true);
		await onAddReward({ tokenAmount, isFinalPayment });
		setTokenAmount(null);
		setIsAddingFunds(false);
	};

	return (
		<ModalComponent
			isOpen={isOpen}
			onClose={onClose}
			modalCross={false}
			moreBlur
		>
			<BoxComponent>
				<Text
					variant='colored'
					textAlign='center'
					fontWeight='700'
					fontSize='20px'
					lineHeight='20px'
				>
					{t('adminPage.addRewards.title')}
				</Text>
				<Divider my='10px' />
				<Stack spacing={0}>
					<Image
						src={`${AWS_S3}/projects/${data.metadataId}/gallery/${data.featureImage}`}
						alt={data.title}
						w='312px'
						aspectRatio={9 / 7}
						objectFit='cover'
						borderRadius='12px 12px 0px 0px '
					/>
					<Text
						fontSize='14px'
						fontWeight='600'
						lineHeight='20px'
						p='10px'
						pb='12px'
						bgColor='transparencyWhite.100'
						textAlign='center'
						borderRadius='0px 0px 12px 12px'
					>
						{data.title}
					</Text>
				</Stack>
				<Stack mt='25px' gap='9px'>
					<Text fontWeight='700' fontSize='16px' lineHeight='16px'>
						{t('adminPage.addRewards.quantity')}
					</Text>
					<Input
						h='50px'
						placeholder={t('adminPage.addRewards.placeHolder')}
						type='number'
						value={tokenAmount}
						onChange={handleChangeValue}
					></Input>
					<Checkbox
						onChange={handleChangeIsFinalPayment}
						isChecked={isFinalPayment}
					>
						<Text> {t('adminPage.addRewards.lastReward')}</Text>
					</Checkbox>
					{errorMessage && (
						<Text variant='error' fontSize='12px'>
							{errorMessage}
						</Text>
					)}
				</Stack>
				<Flex
					mt={errorMessage ? '7px' : '25px'}
					justifyContent='space-between'
				>
					<Button variant='secondary' onClick={onClose}>
						{t('adminPage.addRewards.cancel')}
					</Button>
					<Button
						variant='main'
						onClick={handleClick}
						isLoading={isAddingFunds}
					>
						{t('adminPage.addRewards.add')}
					</Button>
				</Flex>
			</BoxComponent>
		</ModalComponent>
	);
};

export default ModalAddReward;
