import { PublicRoutes } from '../routes/Routes';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Stack, Text, Link, Button, useColorMode } from '@chakra-ui/react';

import InputComponent from './InputComponent';
import BoxComponent from './BoxComponent';

import { login } from '../services/authService';
import GridCenterContainer from './GridCenterContainer';
import { useTranslation } from 'react-i18next';

/**
*
    @name Login
    @description Component for the login page.
                 Allows users to enter their email and password to sign in.
                 Includes validations to ensure the email contains "@" and the password is at least 8 characters long.
    @returns {JS.Element} index.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const initialState = {
	username: '',
	password: '',
};

const LoginComponent = ({ isModal = false }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setColorMode } = useColorMode();
	const [credentials, setCredentials] = useState(initialState);
	const [errorMsg, setErrorMsg] = useState();

	const handleChange = event => {
		const { name, value } = event.target;
		if (errorMsg) {
			setErrorMsg(undefined);
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const signIn = async () => {
		if (!credentials?.username || credentials?.password.length < 8) {
			setErrorMsg('Enter a valid user name and password.');
			return;
		}

		const { success, data, message } = await login(credentials);

		if (success) {
			const { appearance } = data;

			if (appearance) {
				setColorMode(appearance);
			}

			navigate(`/${PublicRoutes.VerifyAccount}`, {
				state: {
					userInfo: { ...data, projects: [] },
					type: !data?.isVerified ? 'verify' : 'magic',
				},
			});
		} else {
			setErrorMsg(message);
		}
	};

	return (
		<GridCenterContainer w='100%'>
			<BoxComponent
				headerMsg={t('login.headerMsg')}
				explainMsg={t('login.explainMsg')}
				w={{ base: null, sm: '400px' }}
			>
				<Stack spacing={0} gap='25px'>
					<InputComponent
						label={t('login.inputUsername.label')}
						value={credentials.email}
						errorMsg={errorMsg}
						onChange={handleChange}
						name={'username'}
						placeholder={t('login.inputUsername.placeholder')}
						type={'text'}
						setErrorMsg={setErrorMsg}
						isModal={isModal}
					/>
					<Stack spacing={0}>
						<InputComponent
							login={true}
							label={t('login.inputPassword.label')}
							placeholder={t('login.inputPassword.placeholder')}
							value={credentials.password}
							errorMsg={errorMsg}
							onChange={handleChange}
							name='password'
							type={'password'}
							setErrorMsg={setErrorMsg}
							isModal={isModal}
							enterAction={signIn}
						/>

						<Text textAlign='end' fontSize={12} mt='2px'>
							<Link
								fontWeight='400'
								fontSize='12px'
								as={NavLink}
								to={`/${PublicRoutes.ResetPassword}`}
							>
								{t('login.forgotPassword')}
							</Link>
						</Text>
					</Stack>

					<Stack w='100%' spacing={2}>
						<Button
							w='100%'
							h='34px'
							variant='main'
							fontWeight='400'
							fontSize='13px'
							borderRadius='5px'
							onClick={signIn}
						>
							{t('login.signIn')}
						</Button>
						<Text
							textAlign='center'
							fontSize='12px'
							fontWeight='400'
						>
							{t('login.signUp')}
							<Link
								fontWeight='700'
								as={NavLink}
								fontSize='12px'
								to='/register'
							>
								{t('login.singUpLink')}
							</Link>
						</Text>
					</Stack>
					{errorMsg && (
						<Text
							fontWeight='700'
							color='negative'
							textAlign='center'
						>
							{errorMsg}
						</Text>
					)}
				</Stack>
			</BoxComponent>
		</GridCenterContainer>
	);
};

export default LoginComponent;
