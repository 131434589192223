import { Stack, useMediaQuery } from '@chakra-ui/react';
import FinancialDetailsBoxInfo from './FinancialDetailsBoxInfo';
import ProjectCostSummaryBoxInfo from './ProjectCostSummaryBoxInfo';
import RentalIncomeBoxInfo from './RentalIncomeBoxInfo';
import CapitalGrowthBoxInfo from './CapitalGrowthBoxInfo';

const InfoBoxesComponent = ({ propertyInfo }) => {
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	return (
		<Stack w={isTablet ? '100%' : '403px'} gap='25px'>
			<FinancialDetailsBoxInfo propertyInfo={propertyInfo} />
			<ProjectCostSummaryBoxInfo propertyInfo={propertyInfo} />
			<RentalIncomeBoxInfo propertyInfo={propertyInfo} />
			<CapitalGrowthBoxInfo propertyInfo={propertyInfo} />
		</Stack>
	);
};

export default InfoBoxesComponent;
