/* eslint-disable react-hooks/exhaustive-deps */
import { useParams, useNavigate } from 'react-router-dom';
import { IconBack } from '../../assets/Icons';
import { Flex, Box, Text, Button, useMediaQuery } from '@chakra-ui/react';
import ProjectGeneralInfo from './components/ProjectGeneralInfo';
import SliderPhotos from './components/SliderPhotos';
import GalleryPhoto from './components/GalleryPhoto/GalleryPhoto';
import ProjectMoreInfo from './components/ProjectMoreInfo/ProjectMoreInfo';
import FundingTimeline from './components/FundingTimeline';
import OfferDetails from './components/OfferDetails';
import { PublicRoutes } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
	getProjectInfo,
	getProjectState,
} from '../../services/propertyService';
import LoadingComponent from '../../components/LoadingComponent';
import { AWS_S3 } from '../../constants/constants';
import { availableTokens } from '../../utils/utils';
import DetailsComponent from './components/ProjectMoreInfo/DetailsTab/DetailsComponent';
import FaqsComponent from './components/ProjectMoreInfo/DetailsTab/FaqsComponent';
import DisclaimerComponent from './components/DisclaimerComponent';

const ProjectDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const [isTablet] = useMediaQuery('(max-width: 768px)');
	const [isLoading, setLoading] = useState(true);
	const [projectInfo, setProjectInfo] = useState(null);
	const [projectStatus, setProjectStatus] = useState();

	const projectImages = projectInfo?.gallery.map(image => {
		return `${AWS_S3}/projects/${projectInfo.metadataId}/gallery/${image.fileName}`;
	});

	// The calculator appears when you are within the funding period and there are tokens available for mining.
	const isFundingAvailable =
		new Date() > new Date(projectInfo?.financingStart) &&
		new Date() < new Date(projectInfo?.financingEnd) &&
		availableTokens({
			mintedSupply: projectInfo?.mintedSupply,
			quantityOfTokens: projectInfo?.quantityOfTokens,
		}) > 0;

	useEffect(() => {
		if (id) {
			const getProjectDetails = async () => {
				const { data } = await getProjectInfo(id);
				setProjectInfo(data);
				setProjectStatus(getProjectState(data));
				setLoading(false);
			};
			getProjectDetails();
		}
	}, []);

	return (
		<>
			{isLoading ? (
				<LoadingComponent />
			) : (
				<>
					{isMobile ? (
						<SliderPhotos
							projectImages={projectImages}
							projectId={projectInfo?.projectId}
						/>
					) : (
						<>
							<Flex
								justify='space-between'
								pb='15px'
								align='center'
							>
								<Button
									variant='secondary'
									onClick={() =>
										navigate(`${PublicRoutes.Home}`)
									}
								>
									<IconBack boxSize='15px' />

									<Text
										fontWeight='400'
										fontSize='13'
										letterSpacing={0.02}
										pl='5px'
									>
										{t('projectDetails.backDiscovery')}
									</Text>
								</Button>
							</Flex>
							<GalleryPhoto
								projectInfo={projectInfo}
								projectImages={projectImages}
								projectStatus={projectStatus}
								financingEnd={
									new Date(projectInfo.financingEnd)
								}
							/>
						</>
					)}

					<Flex
						alignItems={{ base: 'center', lg: 'start' }}
						flexDirection={{ base: 'column', lg: 'row' }}
						justifyContent='space-between'
						gap={{ base: '30px', lg: '40px', xl: '60px' }}
						pt='30px'
					>
						<Box maxW='704px' w='100%'>
							<ProjectGeneralInfo
								projectInfo={projectInfo}
								projectStatus={projectStatus}
							/>
							<ProjectMoreInfo projectInfo={projectInfo} />
						</Box>
						<Flex
							flexDirection='column'
							gap='50px'
							align={{ base: 'center', lg: 'end' }}
						>
							{isFundingAvailable && (
								<OfferDetails
									projectInfo={projectInfo}
									projectStatus={projectStatus}
								/>
							)}
							<FundingTimeline projectInfo={projectInfo} />
							{isTablet && (
								<DetailsComponent title='FAQs'>
									{projectInfo.faqs.length ? (
										<FaqsComponent
											faqs={projectInfo.faqs}
										/>
									) : (
										<DisclaimerComponent index={0} />
									)}
								</DetailsComponent>
							)}
						</Flex>
					</Flex>
					{/* <DisclaimerComponent /> */}
				</>
			)}
		</>
	);
};

export default ProjectDetails;
