import {
	Box,
	Card,
	CardBody,
	Flex,
	Image,
	Stack,
	Text,
	useBoolean,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react';

import InfoPropertyCard from './InfoPropertyCard';
import PropertyCardHover from './PropertyCardHover';
import { propertyPercentage } from '../../utils/utils';
import { useRef } from 'react';
import { AWS_S3 } from '../../constants/constants';
import SendProjectTokensComponent from '../SendProjectTokensComponent';
import { getProjectState } from '../../services/propertyService';
import { propertyStatus } from '../../data/optionsData';
import { useTranslation } from 'react-i18next';

/**
*
    @name PropertyCardComponent
    @description It is the component that shows the photos and the information of the property in a Card format.
    @returns {JS.Element} PropertyCardComponent.js
    @Version 1.0.0
*/
const PropertyCardComponent = ({ project, isMobile, gallery }) => {
	const imgRef = useRef();
	const { t } = useTranslation();
	const [projectMenuView, setProjectMenuView] = useBoolean(false);
	const { colorMode } = useColorMode();
	const status = getProjectState(project);

	const {
		isOpen: isOpenSendlModal,
		onOpen: onOpenSendModal,
		onClose: onCloseSendModal,
	} = useDisclosure();

	const onSendProjectTokens = e => {
		onOpenSendModal();
	};

	return (
		<>
			{project && (
				<Card borderRadius='12px'>
					<CardBody p='0' w='100%'>
						<Flex
							ref={imgRef}
							position='relative'
							overflow='hidden'
							borderTopRadius='12px'
							alignItems='center'
							justifyContent='center'
							onMouseEnter={() => setProjectMenuView.on()}
							onMouseLeave={() => setProjectMenuView.off()}
						>
							<Box
								position='relative'
							>
								<Flex
									color='black'
									w='100%'
									alignItems='center'
									position='absolute'
									left='5px'
									top='4px'
									p='1'
									gap='8px'
									zIndex={9}
								>
									<Stack
										bg={
											status === propertyStatus.Cancelled
												? 'red.500'
												: colorMode === 'dark'
													? 'brand.200'
													: 'brand.500'
										}
										borderRadius='15px'
										justifyContent='center'
										px='10px'
										py='5px'
									>
										<Text
											fontSize='10px'
											fontWeight='300'
											color={
												status === propertyStatus.Cancelled
													? 'transparencyWhite.800'
													: colorMode === 'dark'
														? 'transparencyBlack.800'
														: 'transparencyWhite.800'
											}
										>
											{t(`sliderPropertyCard.tag.${status}`)}
										</Text>
									</Stack>

								</Flex>
								<Image
									objectFit='cover'
									h='250px'
									w='312px'
									src={`${AWS_S3}/projects/${project?.metadataId}/gallery/${project?.featureImage}`}
									filter={projectMenuView ? 'blur(30px)' : 'none'}
								/>
							</Box>
							{projectMenuView && (
								<PropertyCardHover
									projectId={project.projectId}
									onSend={onSendProjectTokens}
								/>
							)}
						</Flex>

						<InfoPropertyCard
							project={project}
							percentage={propertyPercentage(project)}
						/>
					</CardBody>
				</Card>
			)}
			{isOpenSendlModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendlModal}
					closeSendModal={onCloseSendModal}
					isMobile={isMobile}
					project={project}
					gallery={gallery}
				/>
			)}
		</>
	);
};

export default PropertyCardComponent;
