import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import BoxComponent from './BoxComponent';

const AlertDialogComponent = ({ isOpen, onClose, children, noButton }) => {
	return (
		<AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<BoxComponent maxW='600px' mx='auto' w='377px'>
					{children}
					{noButton && (
						<Button variant='main' ms='auto' onClick={onClose}>
							Close
						</Button>
					)}
				</BoxComponent>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default AlertDialogComponent;
