import { useState } from 'react';
import {
	Image,
	Grid,
	GridItem,
	Box,
	Text,
	Button,
	useColorMode,
	AspectRatio,
} from '@chakra-ui/react';
import { IconPlus } from '../../../../assets/Icons';
import Timer from './Timer';
import { useTranslation } from 'react-i18next';
import ModalGalleryPhoto from './ModalGalleryPhoto';
import { propertyStatus } from '../../../../data/optionsData';
import { AWS_S3 } from '../../../../constants/constants';

const GalleryPhoto = ({
	projectInfo,
	projectImages,
	projectStatus,
	financingEnd,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const [isOpenGalleryModal, setIsOpenGalleryModal] = useState(false);

	const selectedImages = Array.isArray(projectImages)
		? projectImages.filter(
				image =>
					image !==
					`${AWS_S3}/projects/${projectInfo?.metadataId}/gallery/${projectInfo?.featureImage}`,
			)
		: [];

	const openGallery = () => {
		setIsOpenGalleryModal(true);
	};

	return (
		<>
			<Grid
				position='relative'
				templateRows='repeat(2, 1fr)'
				templateColumns='repeat(4, 1fr)'
				gap='10px'
				borderRadius='14px'
				h='516px'
			>
				{projectInfo?.featureImage && (
					<GridItem rowSpan={2} colSpan={2} h={'auto'}>
						<Image
							src={`${AWS_S3}/projects/${projectInfo?.metadataId}/gallery/${projectInfo?.featureImage}`}
							borderRadius='14px 3px 3px 14px'
							w='100%'
							h='100%'
							objectFit='cover'
						/>
					</GridItem>
				)}
				{selectedImages?.slice(0, 4).map((img, idx) => (
					<GridItem key={idx} rowSpan={1} colSpan={1} h={'252px'}>
						{img?.endsWith('.mp4') ? (
							<AspectRatio ratio={1} w='100%' h='100%'>
								<video
									title={`video-${idx}`}
									style={{
										margin: '0px',
										padding: '0px',
										border: 'none',
										objectFit: 'cover',
										borderRadius: '3px',
									}}
									src={img}
									autoPlay
									loop
									muted
								/>
							</AspectRatio>
						) : (
							<Image
								src={img}
								borderRadius={
									idx === 1
										? '3px 14px 3px 3px'
										: idx === 3
											? '3px 3px 14px 3px'
											: '3px'
								}
								w='100%'
								h='100%'
								objectFit='cover'
							/>
						)}
					</GridItem>
				))}
				{projectImages ? (
					<>
						<Box position='absolute' bottom='10px' right='10px'>
							<Button
								variant='main'
								mr='5px'
								onClick={openGallery}
							>
								<IconPlus
									mr='5px'
									boxSize='12px'
									color={
										colorMode === 'dark'
											? 'transparencyBlack.500'
											: 'transparencyWhite.900'
									}
								/>
								<Text fontSize='13px'>
									{t('projectDetails.galleryPhoto.button')}
								</Text>
							</Button>
						</Box>

						{projectStatus !== propertyStatus.OpenMarket &&
						projectStatus !== propertyStatus.Funded ? (
							<Box position='absolute' top='10px' left='10px'>
								<Timer
									endDate={financingEnd}
									isCancelled={
										projectStatus ===
										propertyStatus.Cancelled
									}
								/>
							</Box>
						) : null}
					</>
				) : null}
			</Grid>
			<ModalGalleryPhoto
				isOpenGalleryModal={isOpenGalleryModal}
				setIsOpenGalleryModal={setIsOpenGalleryModal}
				selectedImages={projectImages}
			/>
		</>
	);
};

export default GalleryPhoto;
