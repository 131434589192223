import BoxComponent from '../../../components/BoxComponent';
import { Grid, Text } from '@chakra-ui/react';
import InfoDashboardComponent from './InfoDashboardComponent';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getDashboardOverview } from '../../../services/userService';

/**
*
    @name OverviewComponent
    @description Component that give the user a general view of the projects, the status of them and the general revenue.
	@returns {JSX.Element} OverviewComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const OverviewComponent = ({ ownedProjects, isTablet }) => {
	const { t } = useTranslation();
	const [overviewInfo, setOverviewInfo] = useState();
	const overviewData = overviewInfo?.data;

	useEffect(() => {
		const getOvervierwInformation = async () => {
			const _overviewInfo = await getDashboardOverview();
			setOverviewInfo(_overviewInfo);
		};
		getOvervierwInformation();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BoxComponent h='100%' w='100%' p='0' inPadding='15px 20px' gap='8px'>
			<Text fontSize='16' fontWeight='700'>
				{t('dashboard.overview.title')}
			</Text>
			<Grid
				templateColumns={
					isTablet
						? 'repeat(2, minmax(0, 1fr))'
						: 'repeat(3, minmax(0, 1fr))'
				}
				h='100%'
				placeItems='center'
				gap='10px'
			>
				<InfoDashboardComponent
					header={t('dashboard.overview.totalInvested')}
					info={currencyFormatter(overviewData?.totalInvested)}
					blur={overviewInfo?.success !== true}
				/>

				<InfoDashboardComponent
					header='Total P&L'
					info={currencyFormatter(overviewData?.totalProfitAndLoss)}
					blur={overviewInfo?.success !== true}
				/>

				<InfoDashboardComponent
					header='ROI'
					info={Number(overviewData?.totalROI)?.toFixed(2) + '%'}
					blur={overviewInfo?.success !== true}
				/>

				<InfoDashboardComponent
					header={t('dashboard.overview.projectExited')}
					info={overviewData?.exitedProjects}
					blur={overviewInfo?.success !== true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.overview.projectOwner')}
					info={ownedProjects ?? 0}
				/>
			</Grid>
		</BoxComponent>
	);
};

export default OverviewComponent;
