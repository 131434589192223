import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';
import {
	getAnnualRentalYield,
	getEstimatedMonthlyPayment,
} from '../../../../../../utils/projectDataUtils';

const RentalIncomeOverview = ({ data }) => {
	const { t } = useTranslation();
	const {
		estimatedAnnualIncomeFromRental,
		rentalTimeline,
		distributionFrequency,
	} = data;

	return (
		<FinancialSummaryComponent
			title={t(
				`projectDetails.projectMoreInfo.rentalIncomeOverview.title.${data?.category}`,
			)}
		>
			{(data?.category === 'Rental' ||
				(data?.category === 'Build' &&
					data?.subcategory.toLowerCase().includes('rent'))) && (
				<>
					<InfoSummaryComponent
						header={t(
							'projectDetails.projectMoreInfo.rentalIncomeOverview.estimatedAnnualNetIncomeFromRental',
						)}
						amount={estimatedAnnualIncomeFromRental}
					/>
					<InfoSummaryComponent
						header={t(
							'projectDetails.projectMoreInfo.rentalIncomeOverview.rentalTimeline',
						)}
						amount={rentalTimeline}
						month
					/>
				</>
			)}
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.rentalIncomeOverview.frecuencyOfDistribution',
				)}
				amount={distributionFrequency}
				string
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.rentalIncomeOverview.estimatedMonthlyPayment',
				)}
				amount={getEstimatedMonthlyPayment(data)}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.rentalIncomeOverview.annualRentalYield',
				)}
				amount={getAnnualRentalYield(data)}
				porcent
			/>
		</FinancialSummaryComponent>
	);
};

export default RentalIncomeOverview;
