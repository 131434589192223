import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useMediaQuery,
	useToast,
} from '@chakra-ui/react';
import {
	currencyFormatter,
	getFundedThreshold,
	multiplicationCalc,
} from '../../../utils/utils';
import {
	IconView,
	IconEdit,
	IconDots,
	IconPublish,
	IconTrash,
	IconTransactions,
	IconWithdraw,
	IconDeposit,
	IconClose,
} from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import {
	publishProject,
	updatePropertyAndNonce,
} from '../../../services/propertyService';
import useProject from '../../../hooks/useProject';
import IconReload from '../../../assets/Icons/components/IconReload';
import { showToast } from '../../../utils/Toasts';
import { useState } from 'react';
import { buttonActions, projectStatus } from '../../../data/optionsData';
import useSeller from '../../../hooks/useSeller';
import useClaim from '../../../hooks/useClaim';
import ModalAddReward from './ModalAddReward';
import BoxComponent from '../../../components/BoxComponent';
import TooltipComponent from '../../../components/TooltipTableComponent';
import AlertDialogSuccesfulTransaction from '../../../components/AlertDialogSuccesfulTransaction';
import AlertDialogDoubleCheck from '../../../components/AlertDialogDoubleCheck';

const PublishedInfoComponent = ({
	isMobile,
	colorBorder,
	data,
	last,
	handleDeleteProject,
	loadingAction,
	onPublishProject,
	onWithdrawProject,
	onRefundProject,
	onCancelProject,
}) => {
	const {
		metadataId,
		pricePerToken,
		quantityOfTokens,
		mintedSupply,
		investmentThreshold,
		title,
		projectId,
	} = data;

	const { createProjectAndUpdateStatus, existsProject } = useProject();
	const { withdrawProjectFunds, enableRefund } = useSeller();
	const { addFunds } = useClaim();
	const {
		onOpen: openAddRewardsModal,
		isOpen: isOpenAddRewardsModal,
		onClose: closeAddRewardsModal,
	} = useDisclosure();

	const {
		isOpen: isDoubleCheckOpen,
		onOpen: openDoubleCheckModal,
		onClose: closeDoubleCheckModal,
	} = useDisclosure();

	const {
		isOpen: isSucessfulOpen,
		onOpen: openSuccessfulModal,
		onClose: closeSuccesfulModal,
	} = useDisclosure();

	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const navigate = useNavigate();
	const toast = useToast();
	const [txHash, setTxHash] = useState(null);
	const { t } = useTranslation();
	const [isPublishing, setIsPublishing] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [action, setAction] = useState();

	const handleCloseSuccessfulModal = () => {
		setErrorMessage();
		setTxHash(null);
		closeSuccesfulModal();
	};

	const handleEditProject = () => {
		navigate(`/${PrivateRoutes.AddProperty}`, {
			state: { projectId },
		});
	};

	const handleCloseAddRewardModal = () => {
		setErrorMessage();
		setTxHash(null);
		closeAddRewardsModal();
	};

	const handleViewProject = () => {
		navigate(`/${PublicRoutes.Project}/${projectId}`);
	};

	const handlePublishProject = async () => {
		openSuccessfulModal();
		try {
			if (data.status !== projectStatus.Published) {
				setIsPublishing(true);

				const publishedProjectData = await publishProject(projectId);

				if (!publishedProjectData.success) {
					setIsPublishing(false);
					setErrorMessage(publishedProjectData.message);
					return;
				}

				const newProject = await createProjectAndUpdateStatus(
					publishedProjectData.data.projectData,
					publishedProjectData.data.projectId,
				);

				if (newProject.newProject.status === projectStatus.Published) {
					setTxHash(newProject.receipt?.hash);
					onPublishProject(newProject.newProject);
				} else {
					setErrorMessage('Error publishing project');
				}
				setIsPublishing(false);
			}
		} catch (error) {
			setIsPublishing(false);
			showToast(toast, 'There was an error', error?.reason);
		}
	};

	const handleReloadProject = async () => {
		setIsPublishing(true);
		const projectAlreadyExists = await existsProject(metadataId);

		if (projectAlreadyExists) {
			const projectData = await publishProject(projectId);
			await updatePropertyAndNonce(
				{
					status: projectStatus.Published,
					nonce: projectData.data?.projectData.nonce,
				},
				projectId,
			);
		} else {
			await handlePublishProject();
		}

		setIsPublishing(false);
	};

	const handleCancelProject = async () => {
		openSuccessfulModal();
		if (isDoubleCheckOpen) closeDoubleCheckModal();
		const cancelProjectTx = await enableRefund(metadataId);
		if (cancelProjectTx.success) {
			setTxHash(cancelProjectTx.receipt?.hash);
			onRefundProject(metadataId);
			setIsPublishing(false);
			onCancelProject(metadataId);
			return cancelProjectTx;
		} else {
			setIsPublishing(false);
			setErrorMessage(
				'Error canceling project: ' + cancelProjectTx.errorMessage,
			);
		}
	};

	const handleWithdrawProject = async () => {
		setIsPublishing(true);
		openSuccessfulModal();
		const withdrawTx = await withdrawProjectFunds(metadataId);
		if (withdrawTx.success) {
			setTxHash(withdrawTx.receipt?.hash);
			openSuccessfulModal();
			setIsPublishing(false);
			onWithdrawProject(metadataId);
		} else {
			setIsPublishing(false);
			setErrorMessage(
				'Error withdrawing funds: ' + withdrawTx.errorMessage,
			);
		}
	};

	const handleAddRewards = async ({
		tokenAmount,
		isFinalPayment = false,
	}) => {
		if (errorMessage) setErrorMessage();
		const addFundsTx = await addFunds({
			metadataId,
			projectId,
			tokenAmount,
			isFinalPayment,
		});

		if (addFundsTx.success) {
			setTxHash(addFundsTx.receipt?.hash);
			closeAddRewardsModal();
			openSuccessfulModal();
		} else {
			setErrorMessage('Error adding funds');
		}
	};

	const buttonConfig = {
		AddRewards: {
			variant: 'main',
			text: t('adminPage.pubProperties.AddReward'),
			icon: <IconDeposit />,
			isDisabled: false,
			onClick: openAddRewardsModal,
			doubleCheck: false,
		},
		Draft: {
			variant: 'main',
			text: t('adminPage.pubProperties.publish'),
			icon: <IconPublish />,
			isDisabled: false,
			onClick: handleReloadProject,
			doubleCheck: true,
		},
		Published: {
			variant: 'main',
			text: t('adminPage.pubProperties.withdraw'),
			icon: <IconWithdraw />,
			isDisabled:
				getFundedThreshold({ mintedSupply, quantityOfTokens }) <
				investmentThreshold,
			onClick: handleWithdrawProject,
			doubleCheck: true,
		},
		Reload: {
			variant: 'reload',
			text: t('adminPage.pubProperties.reload'),
			icon: <IconTransactions />,
			isDisabled: false,
			onClick: handleReloadProject,
			doubleCheck: false,
		},
		Pending: {
			variant: 'main',
			text: t('adminPage.pubProperties.Pending'),
			icon: <IconDots />,
			isLoading: true,
			isDisabled: true,
			onClick: handleReloadProject,
			doubleCheck: false,
		},
		Refund: {
			variant: 'secondary',
			text: t('adminPage.pubProperties.Refund'),
			icon: <IconWithdraw />,
			isDisabled: true,
			onClick: handleCancelProject,
			doubleCheck: true,
		},
		Ended: {
			variant: 'secondary',
			text: t('adminPage.pubProperties.Ended'),
			icon: <IconWithdraw />,
			isDisabled: true,
			onClick: null,
			doubleCheck: false,
		},
	};

	const handleOpenDoubleCheckModal = action => {
		setAction(action);
		openDoubleCheckModal();
	};

	const {
		variant: buttonVariant,
		text: buttonText,
		icon: buttonIcon,
		isDisabled: buttonIsDisabled,
		onClick: buttonOnClick,
		doubleCheck,
	} = (data.isWithdrawn === true &&
		data.status !== projectStatus.Ended &&
		buttonConfig.AddRewards) ||
	buttonConfig[data.status];
	return (
		<>
			<GridItem
				w={isMobile ? '80px' : isTablet ? '150px' : '250px'}
				py={isMobile ? '15px' : '0'}
				textAlign={'start'}
			>
				<TooltipComponent
					label={title}
					fontSize={isMobile ? '12px' : '14px'}
					fontWeight={isMobile ? '600' : '500'}
					px={2}
				/>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(
					multiplicationCalc(quantityOfTokens, pricePerToken),
				)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{t(`adminPage.pubProperties.${data.status}`)}
			</Text>

			{!isMobile && (
				<Flex w='100%' align='center' p='6px' gap='10px'>
					{data.status !== projectStatus.Pending && (
						<Button
							variant='secondary'
							fontWeight='400'
							fontSize='13px'
							w='68px'
							p='10px'
							gap='5px'
							onClick={handleViewProject}
							isDisabled={loadingAction}
						>
							<IconView />
							{t('adminPage.pubProperties.view')}
						</Button>
					)}

					<Button
						variant={buttonVariant}
						w={
							data.status !== projectStatus.Pending
								? '96px'
								: '174px'
						}
						fontWeight='400'
						p='10px'
						isDisabled={buttonIsDisabled}
						onClick={
							doubleCheck
								? () =>
										handleOpenDoubleCheckModal(
											data.status === projectStatus.Draft
												? buttonActions.Publish
												: buttonActions.Withdraw,
										)
								: buttonOnClick
						}
						isLoading={
							isPublishing ||
							data.status === projectStatus.Pending
						}
					>
						<Flex w='100%' alignItems='center'>
							{buttonIcon}
							<Text ms='5px' justifySelf='start' fontSize='13px'>
								{buttonText}
							</Text>
						</Flex>
					</Button>

					{data.status !== projectStatus.Pending &&
						data.status !== projectStatus.Refund && (
							<Menu>
								<MenuButton
									h='40px'
									w='40px'
									borderRadius='5px'
								>
									<IconDots />
								</MenuButton>
								<MenuList>
									<MenuItem
										gap='5px'
										isDisabled={loadingAction}
										onClick={handleEditProject}
									>
										<IconEdit />
										{t('adminPage.pubProperties.edit')}
									</MenuItem>
									{data.status === projectStatus.Draft && (
										<MenuItem
											gap='5px'
											isDisabled={loadingAction}
											onClick={handleDeleteProject}
										>
											<IconTrash />
											{t(
												'adminPage.pubProperties.delete',
											)}
										</MenuItem>
									)}
									{!data.isWithdrawn &&
										data.status ===
											projectStatus.Published && (
											<MenuItem
												gap='5px'
												isDisabled={loadingAction}
												onClick={() => {
													handleOpenDoubleCheckModal(
														buttonActions.Refund,
													);
												}}
											>
												<IconClose />
												{t(
													'adminPage.pubProperties.cancel',
												)}
											</MenuItem>
										)}
								</MenuList>
							</Menu>
						)}
				</Flex>
			)}
			{isMobile && (
				<GridItem colSpan={0}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem
								gap='5px'
								onClick={handleEditProject}
								isDisabled={
									loadingAction ||
									data.status === projectStatus.Refund
								}
							>
								<IconView />
								{t('adminPage.pubProperties.edit')}
							</MenuItem>
							<MenuItem
								gap='5px'
								isDisabled={loadingAction}
								onClick={handleViewProject}
							>
								<IconView />
								{t('adminPage.pubProperties.view')}
							</MenuItem>
							{data.status !== projectStatus.Reload ? (
								<MenuItem
									gap='5px'
									isDisabled={
										loadingAction ||
										data.status !== projectStatus.Draft
									}
									onClick={() =>
										handleOpenDoubleCheckModal(
											data.status === projectStatus.Draft
												? buttonActions.Publish
												: buttonActions.Withdraw,
										)
									}
								>
									{buttonIcon}
									{buttonText}
								</MenuItem>
							) : (
								<MenuItem
									gap='5px'
									onClick={handlePublishProject}
								>
									<IconReload />
									{t('adminPage.pubProperties.reload')}
								</MenuItem>
							)}

							<MenuItem
								gap='5px'
								isDisabled={
									loadingAction ||
									data.status !== projectStatus.Draft
								}
								onClick={handleDeleteProject}
							>
								<IconTrash />
								Delete
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isMobile ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}

			<AlertDialogSuccesfulTransaction
				isOpen={isSucessfulOpen}
				txHash={txHash}
				onClose={handleCloseSuccessfulModal}
				text={t(
					'projectDetails.offerDetails.succesful.transactionSuccess.description',
				)}
				errorMessage={errorMessage}
			/>

			<AlertDialogDoubleCheck
				action={action}
				isOpen={isDoubleCheckOpen}
				onClose={closeDoubleCheckModal}
				onConfirm={
					action === buttonActions.Publish
						? handlePublishProject
						: action === buttonActions.Refund
							? handleCancelProject
							: handleWithdrawProject
				}
			/>

			<ModalAddReward
				isOpen={isOpenAddRewardsModal}
				onClose={handleCloseAddRewardModal}
				onAddReward={handleAddRewards}
				data={data}
				errorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
			>
				<BoxComponent></BoxComponent>
			</ModalAddReward>
		</>
	);
};

export default PublishedInfoComponent;
