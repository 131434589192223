import { Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { formatOrDash } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

const AccountBalanceComponent = ({
	hiddenInfo,
	balance,
	colorMode,
	isMobile,
}) => {
	const { t } = useTranslation();
	const hiddenInfoString = '*****';

	return (
		<Flex
			minW={isMobile ? '312px' : '530px'}
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.950'
			}
			justifyContent='center'
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			p='20px'
			h='100%'
		>
			<Stack textAlign='center' alignItems='center' gap='20px' my='auto'>
				<Text
					variant='colored'
					fontSize='24px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					{t('walletPage.accountBalance')}
				</Text>
				<Stack gap='5px' mb='5px'>
					<Text fontSize='10px' variant='gray' letterSpacing={-0.02}>
						{t('walletPage.totalAmountFIAT')}
					</Text>
					<Stack>
						{balance === null || balance === undefined ? (
							<Skeleton h='30px' mb='20px' />
						) : (
							<Text
								fontWeight='700'
								fontSize='24px'
								letterSpacing={-0.02}
								lineHeight='20px'
								p='5px'
								mb='20px'
							>
								{hiddenInfo
									? hiddenInfoString
									: formatOrDash(balance) === '-'
										? '$ 0'
										: formatOrDash(balance)}
							</Text>
						)}
					</Stack>
				</Stack>
			</Stack>
		</Flex>
	);
};

export default AccountBalanceComponent;
