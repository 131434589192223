import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// --------------------- Redux --------------------
import { Provider } from 'react-redux';
import { store } from './redux/store/Store.js';
// ------------------------------------------------

import App from './App';

// ----------- Chakra UI + Custom theme -----------
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme/Theme';

// ------------------------------------------------

// ----------- CONSTANSTS -------------------------
import { API_ROUTE } from './constants/constants';

// ------------------------------------------------

// ----------------- i18n --------------------------
import './lib/i18n.js';
// ------------------------------------------------

// ----------------- Cookie login -----------------
import axios from 'axios';
import { ContractProvider } from './Providers/ContractsProvider.js';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_ROUTE;
// ------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<ContractProvider>
					<App />
				</ContractProvider>
			</ChakraProvider>
		</Provider>
	</BrowserRouter>,
);
