import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';

import {
	IconDots,
	IconTrade,
	IconTransfer,
	IconView,
} from '../../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../../routes/Routes';

const OptionsComponent = ({ onOpenSendModal, project }) => {
	const [isMobile] = useMediaQuery('(max-width: 1280px)');
	const navigate = useNavigate();

	const { colorMode } = useColorMode();
	const colorBorder =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyBlack.100';

	return (
		<>
			{!isMobile && (
				<Flex
					w='fit-content'
					borderRadius='5px'
					overflow='hidden'
					py={2}
				>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
						onClick={() =>
							navigate(
								`/${PublicRoutes.Project}/${project.projectId}`,
							)
						}
					>
						<IconView boxSize='14px' mr={1} /> Details
					</Button>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
						onClick={() => onOpenSendModal(project.metadataId)}
					>
						<IconTransfer boxSize='14px' mr={1} /> Transfer
					</Button>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
						onClick={() =>
							navigate(
								`/${PublicRoutes.Project}/${project.projectId}`,
							)
						}
					>
						<IconTrade boxSize='14px' mr={1} /> Comprar
					</Button>
				</Flex>
			)}
			{isMobile && (
				<Menu variant='simple'>
					<MenuButton>
						<IconDots boxSize='14px' />
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() =>
								navigate(
									`/${PublicRoutes.Project}/${project.projectId}`,
								)
							}
						>
							<IconView boxSize='14px' mr={1} /> Details
						</MenuItem>
						<MenuItem
							onClick={() => onOpenSendModal(project.metadataId)}
						>
							<IconTransfer boxSize='14px' mr={1} /> Transfer
						</MenuItem>
						<MenuItem
							onClick={() =>
								navigate(
									`/${PublicRoutes.Project}/${project.projectId}`,
								)
							}
						>
							<IconTrade boxSize='14px' mr={1} /> Comprar
						</MenuItem>
					</MenuList>
				</Menu>
			)}
		</>
	);
};

export default OptionsComponent;
