import AlertDialogComponent from './AlertDialogComponent';
import { Button, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { buttonActions } from '../data/optionsData';
import { useTranslation } from 'react-i18next';

const AlertDialogDoubleCheck = ({ isOpen, onClose, action, onConfirm }) => {
	const { t } = useTranslation();
	const message =
		action === buttonActions.Publish
			? t('adminPage.actions.publish')
			: action === buttonActions.Refund
				? t('adminPage.actions.cancelAction')
				: t('adminPage.actions.withdraw');
	const adviseMessage =
		action === buttonActions.Publish
			? t('adminPage.actions.advisePublish')
			: action === buttonActions.Refund
				? t('adminPage.actions.adviseCancel')
				: t('adminPage.actions.adviseWithdraw');
	const handleConfirm = () => {
		onConfirm();
		onClose();
	};
	return (
		<AlertDialogComponent isOpen={isOpen} onClose={onClose}>
			<Stack>
				<Text fontSize='20px' fontWeight={700}>
					{message}
				</Text>
				<Divider />
				<Text fontSize='13px'>{adviseMessage} </Text>
			</Stack>
			<Flex justifyContent='space-between' mt='20px'>
				<Button variant='secondary' onClick={onClose}>
					{t('adminPage.actions.cancel')}
				</Button>
				<Button variant='main' onClick={handleConfirm}>
					{t('adminPage.actions.confirm')}
				</Button>
			</Flex>
		</AlertDialogComponent>
	);
};

export default AlertDialogDoubleCheck;
