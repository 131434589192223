import numeral from 'numeral';
import {
	IconBalcony,
	IconBathroom,
	IconBBQ,
	IconGym,
	IconLibrary,
	IconParking,
	IconPool,
	IconRooms,
	IconSea,
} from '../assets/Icons';
import { multiplicationCalc, percentageCalc, sumCalc } from './utils';

export const getCostsSum = projectParams => {
	const taxesAndOperatingCosts = numeral(
		projectParams?.taxesAndOperatingCosts,
	).value();
	const currentPropertyValue = numeral(
		projectParams?.currentPropertyValue,
	).value();
	const renovationAndFitoutsCosts = numeral(
		projectParams?.renovationAndFitoutsCosts,
	).value();

	return sumCalc(
		taxesAndOperatingCosts,
		currentPropertyValue,
		renovationAndFitoutsCosts,
	);
};

export const getProjectTimelineMonths = projectParams => {
	const projectStart = projectParams?.projectStart;
	const projectEnd = projectParams?.projectEnd;

	if (projectStart && projectEnd) {
		const minStartDate = new Date(projectStart.from);
		const minStartYear = minStartDate.getFullYear();
		const minStartMonth = minStartDate.getMonth();

		const maxEndDate = new Date(projectEnd.from);
		const maxEndtYear = maxEndDate.getFullYear();
		const maxSEndMonth = maxEndDate.getMonth();

		const yearDiff = maxEndtYear - minStartYear;
		const monthDiff = maxSEndMonth - minStartMonth;

		return yearDiff * 12 + monthDiff;
	}

	return undefined;
};

export const getNetCapitalGainOnSale = projectParams => {
	const estimatedResalePrice = numeral(
		projectParams?.estimatedResalePrice,
	).value();

	return estimatedResalePrice - getTotal(projectParams);
};

export const getEstimatedTotalNetProfit = projectParams => {
	const estimatedAnnualIncomeFromRental = numeral(
		projectParams?.estimatedAnnualIncomeFromRental,
	).value();

	const rentalTimeline = projectParams?.rentalTimeline;

	return (
		(estimatedAnnualIncomeFromRental / 12) * rentalTimeline +
		getNetCapitalGainOnSale(projectParams)
	);
};

export const getElevexFee = projectParams => {
	const currentPropertyValue = numeral(
		projectParams?.currentPropertyValue,
	).value();
	const taxesAndOperatingCosts = numeral(
		projectParams?.taxesAndOperatingCosts,
	).value();
	const renovationAndFitoutsCosts = numeral(
		projectParams?.renovationAndFitoutsCosts,
	).value();

	const costsSum = sumCalc(
		taxesAndOperatingCosts,
		currentPropertyValue,
		renovationAndFitoutsCosts,
	);
	return multiplicationCalc(costsSum, 0.03);
};

export const getTotal = projectParams => {
	return sumCalc(getCostsSum(projectParams), getElevexFee(projectParams));
};

export const getEstimatedMonthlyPayment = projectParams => {
	const estimatedAnnualIncomeFromRental = numeral(
		projectParams?.estimatedAnnualIncomeFromRental,
	).value();

	return estimatedAnnualIncomeFromRental / 12;
};

export const getTotalProjectFundraise = projectParams => {
	const { pricePerToken, quantityOfTokens } = projectParams;

	return multiplicationCalc(pricePerToken, quantityOfTokens);
};

export const getAnnualRentalYield = projectParams => {
	return percentageCalc(
		numeral(projectParams?.estimatedAnnualIncomeFromRental).value(),
		getTotal(projectParams),
	);
};

export const getCapitalGainOnSale = projectParams => {
	const estimatedResalePrice = numeral(
		projectParams?.estimatedResalePrice,
	).value();
	const getTotalResult = getTotal(projectParams);
	return estimatedResalePrice - getTotalResult;
};

export const getEstimatedTotalROI = projectParams => {
	return (
		(getEstimatedTotalNetProfit(projectParams) / getTotal(projectParams)) *
		100
	).toFixed(2);
};

export const getAnnualInvestmentReturn = projectParams => {
	return (
		(getEstimatedTotalNetProfit(projectParams) /
			getTotalProjectFundraise(projectParams)) *
		100 *
		(12 / getProjectTimelineMonths(projectParams))
	).toFixed(2);
};

export const getEstimatedAnnualRentalIncome = (projectParams, value) => {
	return (value * getAnnualRentalYield(projectParams)) / 100 + value;
};

export const getTotalProjectedReturn = (value, projectParams) => {
	const estimatedTotalROI = getEstimatedTotalROI(projectParams);
	const percValue = (estimatedTotalROI / 100) * value;
	return percValue + value;
};

/**
 * Get max diff between first date of project start and last date of project end
 */
export const getEstimatedProjectDuration = projectParams => {
	const start =
		projectParams?.projectStart?.from ?? projectParams?.projectStart?.day;
	const end = projectParams?.projectEnd?.to ?? projectParams?.projectEnd?.day;

	const startDate = new Date(start);
	const endDate = new Date(end);

	const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
	const monthsDiff = endDate.getMonth() - startDate.getMonth();

	return { yearsDiff, monthsDiff };
};

export const formatProjectData = data => {
	const {
		faqs,
		updates,
		timelines: timeline,
		paymentMethods: projectMethodPayments,
		gallery,
		documents,
	} = data;

	const _propertyInfo = data;
	delete _propertyInfo.faqs;
	delete _propertyInfo.updates;
	delete _propertyInfo.timelines;
	delete _propertyInfo.paymentMethods;
	delete _propertyInfo.gallery;
	delete _propertyInfo.documents;

	const _timeline =
		timeline.length > 0
			? timeline.map(item => {
					return {
						...item,
						startDate: item.start,
						endDate: item.end,
					};
				})
			: timeline;

	return {
		data: _propertyInfo,
		faqs,
		updates,
		timeline: _timeline,
		projectMethodPayments,
		gallery,
		documents,
	};
};

export const getInitialEquipments = propertyInfo => {
	return [
		{
			name: 'bedroom',
			icon: IconRooms,
			value: propertyInfo?.bedroom ?? 0,
		},
		{
			name: 'bathroom',
			icon: IconBathroom,
			value: propertyInfo?.bathroom ?? 0,
		},
		{
			name: 'balcony',
			icon: IconBalcony,
			value: propertyInfo?.balcony ?? 0,
		},
		{
			name: 'parking',
			icon: IconParking,
			value: propertyInfo?.parking ?? 0,
		},
		{
			name: 'barbacue',
			icon: IconBBQ,
			value: propertyInfo?.barbacue ?? false,
		},
		{
			name: 'gym',
			icon: IconGym,
			value: propertyInfo?.gym ?? false,
		},
		{
			name: 'library',
			icon: IconLibrary,
			value: propertyInfo?.library ?? false,
		},
		{
			name: 'pool',
			icon: IconPool,
			value: propertyInfo?.pool ?? false,
		},
		{
			name: 'seaView',
			icon: IconSea,
			value: propertyInfo?.seaView ?? false,
		},
	];
};

/**
 * Format property info for requests
 * @param {*} propertyInfo
 */
export const formatProjectParameters = propertyInfo => {
	return {
		data: {
			isDraft: propertyInfo?.isDraft,
			status: propertyInfo?.status,
			address: propertyInfo?.address,
			appreciation: propertyInfo?.appreciation,
			areaM2: propertyInfo?.areaM2,
			category: propertyInfo?.category,
			city: propertyInfo?.city,
			country: propertyInfo?.country,
			coordinates: propertyInfo?.coordinates,
			currentPropertyValue: propertyInfo?.currentPropertyValue,
			description: propertyInfo?.description,
			distributionFrequency: propertyInfo?.distributionFrequency,
			investmentThreshold: propertyInfo?.investmentThreshold,
			estimatedAnnualIncomeFromRental:
				propertyInfo?.estimatedAnnualIncomeFromRental,
			estimatedResalePrice: propertyInfo?.estimatedResalePrice,
			featureImage: propertyInfo?.featureImage,
			financingEnd: propertyInfo?.financingEnd,
			financingStart: propertyInfo?.financingStart,
			isKYCRequired: propertyInfo?.isKYCRequired,
			minTicketPrice: propertyInfo?.minTicketPrice,
			mintedSupply: propertyInfo?.mintedSupply,
			paybackPeriod: propertyInfo?.paybackPeriod,
			pricePerToken: propertyInfo?.pricePerToken,
			postedBy: propertyInfo?.postedBy,
			postalCode: propertyInfo?.postalCode,
			projectEnd: propertyInfo?.projectEnd,
			projectStart: propertyInfo?.projectStart,
			propertyStatus: propertyInfo?.propertyStatus,
			propertyType: propertyInfo?.propertyType,
			purchasedOrPledge: propertyInfo?.purchasedOrPledge,
			quantityOfTokens: propertyInfo?.quantityOfTokens,
			renovationAndFitoutsCosts: propertyInfo?.renovationAndFitoutsCosts,
			rentalTimeline: propertyInfo?.rentalTimeline,
			subcategory: propertyInfo?.subcategory,
			taxesAndOperatingCosts: propertyInfo?.taxesAndOperatingCosts,
			title: propertyInfo?.title,
			withdrawWalletAddress: propertyInfo?.withdrawWalletAddress,
		},
		infoDocuments: propertyInfo?.infoDocuments,
		equipments: propertyInfo.equipments,
		projectMethodPayments: propertyInfo.projectMethodPayments,
		updates: propertyInfo.updates,
		faqs: propertyInfo.faqs,
		timeline: propertyInfo.timeline,
		documents: propertyInfo.documents,
		deletedImages: propertyInfo?.deletedImages,
		deletedUpdates: propertyInfo?.deletedUpdates,
		deletedDocuments: propertyInfo?.deletedDocuments,
		deletedFaqs: propertyInfo?.deletedFaqs,
		deletedTimeline: propertyInfo?.deletedTimeline,
		updatedUpdates: propertyInfo?.updatedUpdates,
		updatedTimeline: propertyInfo?.updatedTimeline,
		updatedFaqs: propertyInfo?.updatedFaqs,
		updatedDocuments: propertyInfo?.updatedDocuments,
	};
};
