import { Grid, useMediaQuery, Spacer, Stack } from '@chakra-ui/react';

import PropertyCardDiscovery from './PropertyCardDiscovery';
import UpcomingGeneralComponent from '../../../components/UpcomingGeneralComponent';
import PaginationComponent from '../../../components/PaginationComponent';

const PropertyGallery = ({
	projects,
	totalProjects,
	page,
	setPage,
	pageSize,
	isScreenLarge,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const [isLarge] = useMediaQuery('(min-width: 1720px)');
	const cardWidth = isMobile ? '100%' : isLarge ? '340px' : '335px';
	return (
		<>
			{projects?.length > 0 ? (
				<>
					<Grid
						w={'100%'}
						templateColumns={`repeat(auto-fit, minmax(0, ${cardWidth}))`}
						gridAutoColumns={{ xl: cardWidth }}
						gap='28px'
						placeContent='center'
					>
						{projects?.map((project, i) => (
							<PropertyCardDiscovery
								key={i}
								project={project}
								maxW={cardWidth}
							/>
						))}
					</Grid>
					{(isMobile && totalProjects > 1) ||
						(!isMobile && totalProjects > 12 && (
							<>
								<Spacer />
								<Stack justify={'center'}>
									<PaginationComponent
										totalProjects={totalProjects}
										page={page}
										setPage={setPage}
										pageSize={pageSize}
									/>
								</Stack>
							</>
						))}
				</>
			) : (
				<UpcomingGeneralComponent
					title='discovery.noProperties'
					paragraph='discovery.noPropertiesSubtitle'
				/>
			)}
		</>
	);
};

export default PropertyGallery;
