import {
	Divider,
	Flex,
	GridItem,
	Text,
	Tooltip,
	useMediaQuery,
} from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';
import { formatDateShort } from '../../../../utils/utils';

const TimelineTableInfo = ({
	step,
	stepIndex,
	last,
	isTablet,
	colorBorder,
	deleteStep,
	editStep,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 550px)');
	return (
		<>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '50px' : isTablet ? '100px' : '150px'}
			>
				<Tooltip label={step.title} placement='top'>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight={isTablet ? '600' : '500'}
						cursor={'default'}
						isTruncated
					>
						{step.title}
					</Text>
				</Tooltip>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{formatDateShort(new Date(step?.startDate))}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{formatDateShort(new Date(step.endDate))}
			</Text>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '50px' : isTablet ? '100px' : '150px'}
			>
				<Tooltip label={step.description} placement='top'>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight={isTablet ? '600' : '500'}
						cursor={'default'}
						isTruncated
					>
						{step.description}
					</Text>
				</Tooltip>
			</GridItem>
			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isMobile ? '10px' : '25px'}
			>
				<IconEdit
					boxSize='14px'
					cursor='pointer'
					onClick={() => editStep(stepIndex)}
				/>
				<IconTrash
					boxSize='14px'
					cursor='pointer'
					onClick={() => deleteStep(stepIndex)}
				/>
			</Flex>
			{!last && <Divider gridColumn='span 6' borderColor={colorBorder} />}
		</>
	);
};

export default TimelineTableInfo;
