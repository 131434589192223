import { performContractFunction } from '../services/ethereumServices';
import useContracts from './useContracts';

const eth = 'ETH';
const usdc = 'USDC';
const usdt = 'USDT';

const useSeller = () => {
	const {
		getSellerContract,
		getUsdcContract,
		getUsdtContract,
		getTreasurySellerContract,
		getOracleContract,
		getElevexTokenContract,
		getSigner,
	} = useContracts();

	/**
	 * @name getUserTokenBalance
	 * @description Obtain the token balance of a user.
	 * @param {string} walletAddress - User's wallet address.
	 * @param {string} projectId - Project ID.
	 * @returns {Promise<BigNumber>} User's token balance.
	 */

	const getUserTokenBalance = async (walletAddress, projectId) => {
		try {
			const sellerContract = await getSellerContract();
			const tokenBalance = await sellerContract.getUserTokenBalance(
				walletAddress,
				projectId,
			);
			return tokenBalance;
		} catch (error) {
			console.error('Error getting user token balance:', error);
			return null;
		}
	};

	/**
	 * @name mintWithUSDT
	 * @description Mint Elevex tokens with USDT.
	 * @param {number} projectId The project ID
	 * @param {number} amount The amount of Elevex tokens to mint
	 * @returns {Promise<Transaction>} The mint transaction
	 */

	const mintWithUSDC = async (projectId, amount) => {
		const mintWithUsdcTx = await mintWithToken('USDC', projectId, amount);
		return mintWithUsdcTx;
	};

	/**
	 * @name mintWithUSDT
	 * @description Mint Elevex tokens with USDT.
	 * @param {number} projectId The project ID
	 * @param {number} amount The amount of Elevex tokens to mint
	 * @returns {Promise<Transaction>} The mint transaction
	 */

	const mintWithUSDT = async (projectId, amount) => {
		const mintWithUsdtTx = await mintWithToken('USDT', projectId, amount);
		return mintWithUsdtTx;
	};

	/**
	 * @name mintWithToken
	 * @description Mint tokens with the chosen token.
	 * @param {string} projectId - Project ID.
	 * @param {string} amount - Amount of tokens to mint.
	 * @returns {Promise<void>} Mint transaction.
	 */

	const mintWithToken = async (token, projectId, amount) => {
		const [signer, sellerContract, elevexTokenContract] = await Promise.all(
			[getSigner(), getSellerContract(), getElevexTokenContract()],
		);

		const totalUsdcPriceInUnits = await getTotalTokenPrice(
			token,
			projectId,
			amount,
		);

		await checkAllowance(token, totalUsdcPriceInUnits, signer);

		const mintResponse =
			token === 'USDC'
				? await performContractFunction(
						sellerContract,
						'mintWithUSDC',
						projectId,
						amount,
					)
				: await performContractFunction(
						sellerContract,
						'mintWithUSDT',
						projectId,
						amount,
					);

		return mintResponse;
		// Espera a que la transacción sea confirmada
	};

	/**
	 * @name mintWithETH
	 * @description Mint Elevex tokens with ETH.
	 * @param {number} projectId The project ID
	 * @param {number} amount The amount of Elevex tokens to mint
	 * @returns {Promise<Transaction>} The mint transaction
	 */

	const mintWithETH = async (projectId, amount) => {
		try {
			const sellerContract = await getSellerContract();
			const ethPrice = await sellerContract.calculateETHPrice(
				projectId,
				amount,
			);

			const mintWithETHTx = await sellerContract.mintWithETH(
				projectId,
				amount,
				{
					value: ethPrice[1],
				},
			);

			const receipt = await mintWithETHTx.wait();

			return { success: true, receipt, errorCode: null };
		} catch (error) {
			console.error('Error minting with ETH:', error);
			return { success: false, receipt: null, errorCode: error.code };
		}
	};

	/**
	 * @name mint
	 * @description Mint Elevex tokens with the chosen token.
	 * @param {string} projectId - Project ID.
	 * @param {string} token - Token to mint with.
	 * @param {string} amount - Amount of tokens to mint.
	 
	 */

	const mint = async (projectId, token, amount) => {
		let receipt = null;
		// const mintEvent = await listenMintEvents();
		switch (token) {
			case eth: {
				receipt = await mintWithETH(projectId, amount);
				break;
			}
			case usdc: {
				receipt = await mintWithUSDC(projectId, amount);
				break;
			}
			case usdt: {
				receipt = await mintWithUSDT(projectId, amount);
				break;
			}
			default:
				break;
		}
		return receipt;
	};

	/**
	 * @name enableRefund
	 * @description Enable refund for a project.
	 * @param {string} projectId - Project ID.
	 * @onlyOwner
	 */

	const enableRefund = async projectId => {
		try {
			const [tokenContract, sellerContract] = await Promise.all([
				getElevexTokenContract(),
				getSellerContract(),
			]);

			const isRefundEnabled =
				await tokenContract.isRefundActive(projectId);

			if (!isRefundEnabled) {
				const enableRefundTx = await performContractFunction(
					sellerContract,
					'enableRefund',
					projectId,
				);
				return enableRefundTx;
			}
		} catch (error) {
			console.error('🚀 ~ enableRefund ~ Error:', error);
		}
	};

	/**
	 * @description - Get the price in USDC of the tokens user wants to mint
	 * @param {number} projectId - The project Id
	 * @param {number} amount - The amount of tokens users wants to mint
	 * @param {Contract} usdcContract - The usdc contract with signer
	 * @param {Contract} elevexTokenContract - The elevex token contract with signer
	 * @param {Signer} signer - The signer of the contracts
	 * @returns
	 */

	const getTotalTokenPrice = async (token, projectId, amount) => {
		const [elevexTokenContract, oracleContract, treasurySellerContract] =
			await Promise.all([
				getElevexTokenContract(),
				getOracleContract(),
				getTreasurySellerContract(),
			]);

		const price = await elevexTokenContract.getProjectPrice(projectId);
		const usdPrice = BigInt(amount) * price;

		const tokenDecimals =
			await treasurySellerContract.getTokenDecimals(token);

		const totalTokenPriceInUnits = await oracleContract.calculateUsdAmount(
			token,
			tokenDecimals,
			usdPrice,
		);

		return totalTokenPriceInUnits;
	};
	/**
	 * @description - Check the allowance of a project
	 * @param {number} totalUsdcPriceInUnits - The price in usdc of the tokens that users wants to buy
	 * @param {Contract} usdcContract - The usdc contract with signer
	 * @param {Contract} treasurySellerContract - The treasury contract with signer
	 * @param {Signer} signer - The signer of the contracts
	 */

	const checkAllowance = async (token, totalUsdcPriceInUnits, signer) => {
		const treasurySellerContract = await getTreasurySellerContract();
		const contract =
			token === usdc ? await getUsdcContract() : await getUsdtContract();
		let allowance = await contract.allowance(
			signer.address,
			treasurySellerContract,
		);
		if (allowance !== totalUsdcPriceInUnits) {
			await performContractFunction(
				contract,
				'approve',
				treasurySellerContract,
				totalUsdcPriceInUnits,
			);

			// Volver a obtener el nuevo allowance después de la aprobación
			allowance = await contract.allowance(
				signer.address,
				treasurySellerContract,
			);
		}
		return allowance === totalUsdcPriceInUnits;
	};

	/**
	 * @name refund
	 * @description Refund the Elevex tokens of a user.
	 * @param {string} walletAddress - User's wallet address.
	 * @param {string} projectId - Project ID.
	 * @returns {Promise<Transaction>} Refund transaction.
	 */

	const refund = async (walletAddress, projectId) => {
		const [elevexTokenContract, sellerContract] = await Promise.all([
			getElevexTokenContract(),
			getSellerContract(),
		]);

		const isRefundEnabled =
			await elevexTokenContract.isRefundActive(projectId);

		const balanceOFElevexToken = await elevexTokenContract.balanceOf(
			walletAddress,
			projectId,
		);
		if (!isRefundEnabled) {
			console.error('Refund Error: Refund is not enabled');
			return;
		}

		if (Number(balanceOFElevexToken) === 0) {
			console.error('Refund Error: Balance is 0 ');
			return;
		}
		const refundTx = await performContractFunction(
			sellerContract,
			'refund',
			projectId,
		);
		return refundTx;
	};

	const withdrawProjectFunds = async projectId => {
		const sellerContract = await getSellerContract();
		const withdrawTx = performContractFunction(
			sellerContract,
			'withdrawProjectFunds',
			projectId,
		);

		return withdrawTx;
	};

	return {
		getUserTokenBalance,
		mintWithUSDC,
		mintWithUSDT,
		mintWithETH,
		mint,
		enableRefund,
		refund,
		withdrawProjectFunds,
	};
};

export default useSeller;
