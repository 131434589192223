import {
	Divider,
	Flex,
	Grid,
	GridItem,
	Text,
	useColorMode,
	useMediaQuery,
	useTheme,
} from '@chakra-ui/react';

import BoxComponent from '../../../../components/BoxComponent';
import FAQsTableInfo from './FAQsTableInfo';
import { useTranslation } from 'react-i18next';

const FAQsTable = ({ currentFAQs, onEditFAQ, onDeleteFAQ }) => {
	const { t } = useTranslation();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const theme = useTheme();
	const { colorMode } = useColorMode();

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	return (
		<BoxComponent w='100%' h='100%' p='0' inPadding='15px' gap='11px'>
			<Text
				fontWeight='700'
				fontSize='16'
				lineHeight='100%'
				letterSpacing={0.02}
			>
				FAQs
			</Text>
			<Flex
				flexDirection='column'
				justifyContent='center'
				h='100%'
				gap='5px'
			>
				<Grid
					templateColumns={'repeat(3, 1fr)'}
					placeItems='center'
					textAlign='center'
					fontSize={isTablet ? '12px' : '14px'}
					border={isTablet ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isTablet ? '12px' : '0'}
					rowGap='0'
					columnGap={isTablet ? '5px' : '20px'}
				>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
						>
							Question
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							Answer
						</Text>
					</GridItem>
					<GridItem
						py={isTablet ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							Option
						</Text>
					</GridItem>
					<Divider
						gridColumn='span 4'
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>
					{currentFAQs.map((faq, index) => (
						<FAQsTableInfo
							key={index}
							faqIndex={index}
							faq={faq}
							last={index === currentFAQs.length - 1}
							isTablet={isTablet}
							colorBorder={colorBorderDesktop}
							updateIndex={index}
							onDeleteFAQ={onDeleteFAQ}
							onEditFAQ={onEditFAQ}
						/>
					))}
				</Grid>
			</Flex>
		</BoxComponent>
	);
};

export default FAQsTable;
