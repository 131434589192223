import {
	IconTrade,
	IconBuilding,
	IconStaking,
	IconWallet,
	IconUser,
} from '../assets/Icons';

export const PublicRoutes = {
	Home: '/',
	Project: 'project',
	Login: 'login',
	ResetPassword: 'reset-password',
	Register: 'register',
	Discover: 'discover',
	HowTo: 'how-to',
	Wallet: 'wallet',
	Trade: 'trade',
	Stake: 'stake',
	VerifyAccount: 'verify-account',
};

export const PrivateRoutes = {
	Dashboard: 'dashboard',
	Notifications: 'notifications',
	Profile: 'profile',
	Offers: 'offers',
	Wallet: 'wallet',
	Settings: 'profile',
	Tutorial: 'tutorial',
	HowTo: 'how-to',
	Admin: 'admin',
	AddProperty: 'add-property',
	Create: 'create',
};

export const MenuLinks = {
	Invest: 'Discover',
	Trade: 'Trade',
	Stake: 'Stake',
};

export const SubMenuTitles = {
	Dashboard: 'Dashboard',
	Notifications: 'Notifications',
	Profile: 'Profile',
	Offers: 'Offers',
	Wallet: 'Wallet',
	Tutorial: 'Tutorial',
	HowTo: 'How to invest',
	Logout: 'Logout',
};

export const headerLinks = [
	{
		title: MenuLinks.Invest,
		to: '/',
		activePathnames: ['', PublicRoutes.Project],
		icon: IconBuilding,
	},
	{
		title: MenuLinks.Trade,
		to: '/trade',
		activePathnames: [PublicRoutes.Trade],
		icon: IconTrade,
	},
	{
		title: MenuLinks.Stake,
		to: '/stake',
		activePathnames: [PublicRoutes.Stake],
		icon: IconStaking,
	},
];

export const subMenuLinks = [
	{
		title: SubMenuTitles.Dashboard,
		to: `/${PrivateRoutes.Dashboard}`,
		icon: IconBuilding,
	},
	/* {
		title: SubMenuTitles.Notifications,
		to: `/${PrivateRoutes.Notifications}`,
		icon: IconNotificationActive,
	}, */
	{
		title: SubMenuTitles.Profile,
		to: `/${PrivateRoutes.Profile}`,
		icon: IconUser,
	},
	/* {
		title: SubMenuTitles.Offers,
		to: `/${PrivateRoutes.Offers}`,
		icon: IconTrade,
	}, */
	{
		title: SubMenuTitles.Wallet,
		to: `/${PrivateRoutes.Wallet}`,
		icon: IconWallet,
	},
];
