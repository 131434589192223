import {
	Divider,
	Flex,
	GridItem,
	Text,
	Tooltip,
	useMediaQuery,
} from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';

const FAQsTableInfo = ({
	faq,
	faqIndex,
	last,
	isTablet,
	colorBorder,
	onDeleteFAQ,
	onEditFAQ,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	return (
		<>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '80px' : isTablet ? '200px' : '350px'}
			>
				<Tooltip label={faq.question} placement='top'>
					<Text
						fontSize={isTablet ? '12px' : '14px'}
						fontWeight={isTablet ? '600' : '500'}
						cursor={'default'}
						isTruncated
					>
						{faq.question}
					</Text>
				</Tooltip>
			</GridItem>
			<GridItem
				py={isTablet ? '15px' : '0'}
				px='2px'
				w={isMobile ? '80px' : isTablet ? '200px' : '350px'}
			>
				<Tooltip label={faq.question} placement='top'>
					<Text
						px='5px'
						variant='gray'
						fontSize={isTablet ? '10px' : '12px'}
						fontWeight='400'
						cursor={'default'}
						isTruncated
					>
						{faq.answer}
					</Text>
				</Tooltip>
			</GridItem>

			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isTablet ? '10px' : '25px'}
			>
				<IconEdit
					boxSize='14px'
					cursor='pointer'
					onClick={() => onEditFAQ(faqIndex)}
				/>
				<IconTrash
					boxSize='14px'
					cursor='pointer'
					onClick={() => onDeleteFAQ(faqIndex)}
				/>
			</Flex>
			{!last && <Divider gridColumn='span 4' borderColor={colorBorder} />}
		</>
	);
};

export default FAQsTableInfo;
