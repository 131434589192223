import {
	Box,
	Text,
	Flex,
	useColorMode,
	useMediaQuery,
	Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';

/**
*
    @name BoxSmallComponent
    @description A component that displays a small box with a title, content, and optional children.
    @returns {JSX.Element} A styled box component with optional child components.
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const BoxSmallComponent = ({
	textTitle,
	textContent,
	w,
	avatar,
	maxW = '250px',
	children,
}) => {
	const { colorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 450px)');
	const bgColor =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.600';
	const [isOpenTooltopTitle, setIsOpenTooltipTitle] = useState(false);
	const [isOpenTooltopContent, setIsOpenTooltipContent] = useState(false);

	return (
		<Flex
			bg={avatar ? '' : bgColor}
			border={avatar ? 'none' : '1px solid'}
			borderColor='transparencyBlack.200'
			borderRadius={10}
			width={w ? { w } : 'fit-content'}
			align='center'
			px='10px'
			py='5px'
			gap='15px'
		>
			{children}

			<Box
				maxW={maxW}
				align={children ? 'start' : 'center'}
				justify='center'
			>
				<Tooltip
					label={textTitle}
					placement='auto'
					isOpen={isOpenTooltopTitle}
				>
					<Text
						whiteSpace='nowrap'
						variant='small'
						isTruncated
						onClick={() =>
							isMobile
								? setIsOpenTooltipTitle(!isOpenTooltopTitle)
								: null
						}
					>
						{textTitle}
					</Text>
				</Tooltip>
				<Tooltip
					label={textContent}
					placement='auto'
					isOpen={isOpenTooltopContent}
				>
					<Text
						whiteSpace='nowrap'
						fontWeight='semibold'
						fontSize={children && !avatar ? '50px' : '13px'}
						isTruncated
						onClick={() =>
							isMobile && maxW >= '50px'
								? setIsOpenTooltipContent(!isOpenTooltopContent)
								: null
						}
					>
						{textContent}
					</Text>
				</Tooltip>
			</Box>
		</Flex>
	);
};

export default BoxSmallComponent;
